import { Card, CardContent, CardHeader, TextField, Typography } from "@mui/material";
import React from 'react';

const BoxsAddRemove = ({ boxId, setBoxTakeDetails, sx, wrapperSx, title, value }) => {

    const handleChange = (value) => {
        setBoxTakeDetails(boxId, value)
    }
    return (
        <React.Fragment>
            <Card
                sx={sx}
            >
                <CardHeader
                    title={
                        title && (
                            <Typography variant={"h4"} mb={0}>{title}</Typography>
                        )
                    }
                />
                <CardContent
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme => theme.palette.action.hover,
                        ...wrapperSx,
                    }}
                >
                    <TextField
                        fullWidth
                        type='text'
                        value={value}
                        placeholder="Content.."
                        label="Content"
                        variant="outlined"
                        name="content"
                        multiline
                        onChange={(e) => handleChange(e?.target?.value)}
                    />
                </CardContent>
            </Card>

        </React.Fragment>


    );
};


export default BoxsAddRemove;









