import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { employeeLeaveApprovalDetail, hrEmployeeApproveLeaveCleanUp } from 'app/redux/actions/subTaskAction';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import LeaveApprovalFrom from './LeaveApprovalFrom';


export default function EmployeeLeaveApprove() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();

  const { employeeLeaveApprovalData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);
  var _id = pageId;
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => showLeaveApproveDialog(params.row.leaveId)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
            </NavLink>
          </div>
        );
      },
    },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'leaveType',
      headerName: 'Type',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'leaveReason',
      headerName: 'Reason',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 250,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'actionRemark',
      headerName: 'Remark',
      sortable: false,
      filterable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'leaveTime',
      headerName: 'Time',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'leaveStartDate',
      headerName: 'Start Date',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'leaveEndDate',
      headerName: 'End Date',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'actionAt',
      headerName: 'Action On',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'actionBy',
      headerName: 'Action By',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
  ];

  const rows = employeeLeaveApprovalData.map((item) => {
    return (
      {
        id: _id++,
        employeeName: item?.employee_id?.name,
        employeeId: item?.employee_id?.id,
        leaveId: item?.id,
        leaveType: item?.type,
        leaveReason: item?.reason,
        status: item?.status ? item?.status : 'Requested',
        actionRemark: item?.action_remark || '--',
        leaveTime: item?.time,
        leaveStartDate: moment(item?.start_date).format('DD MMM YYYY'),
        leaveEndDate: moment(item?.end_date).format('DD MMM YYYY'),
        actionAt: moment(item?.action_at).format('DD MMM YYYY'),
        actionBy:  item?.action_by?.name ? item?.action_by?.name : "-"
      }
    )
  })

  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }

  const handleExport = () => {
    var filterQuery;
    if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {
      filterQuery = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`
    }
    else {
      filterQuery = ''
    }

    subTaskService.getLeaveApprovalData(filterQuery).then((data) => {

      const mockData = data?.data?.data?.map((item, index) => {
        return ({
          "id": index + 1,
          "employeeName": item?.employee_id?.name || '--',
          "leaveType": item?.type || '--',
          "leaveReason": item?.reason || '--',
          "status": item?.status ? item?.status : 'Requested',
          "actionRemark": item?.action_remark || '--',
          "leaveTime": item?.time || '--',
          "leaveStartDate": moment(item?.start_date).format('DD MMM YYYY') || '--',
          "leaveEndDate": moment(item?.end_date).format('DD MMM YYYY') || '--',
          "actionAt": moment(item?.action_at).format('DD MMM YYYY') || '--',
          "actionBy": item?.action_by?.name ? item?.action_by?.name : "-",
        })
      })

      function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
          }

          str += line + '\r\n';
        }

        return str;
      }

      function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
          items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

      var headers = {

        id: "ID",
        employeeName: "Employee Name",
        leaveType: "Leave Type",
        leaveReason: "Reason",
        status: "Status",
        actionRemark: "Remark",
        leaveTime: "Time",
        leaveStartDate: "Start Date",
        leaveEndDate: "End Date",
        actionAt: "Action At",
        actionBy: "Action By"
      };

      var fileTitle = 'Employee Leave'; // or 'my-unique-title'



      exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download


    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
  }
  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

      filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    } else {
      filterString = ''
    }
    subTaskService.getLeaveApprovalData(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(employeeLeaveApprovalDetail(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(hrEmployeeApproveLeaveCleanUp())
    }
  }, [offset, queryOptions])
  const handleLeaveUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showLeaveApproveDialog = React.useCallback((leaveId) => {
    showDialog({
      title: "Leave",
      content: <LeaveApprovalFrom leaveId={leaveId} onSave={handleLeaveUpdate} />
    });
  }, [handleLeaveUpdate, showDialog]);

  return (
    <>
      <div style={{ height: '70px', width: '100%' }}>
        <Button sx={{ float: 'right' }} variant="contained" onClick={handleExport}>
          Export CSV
        </Button>
      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGrid sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}


        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}