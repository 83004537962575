export const SUBTASK_GET_DATA = "set-on-subtask-get-data"
export const SUBTASK_DETAIL_GET_DATA = "set-on-subtask-detail-get-data"
export const SUBTASK_DETAIL_GET_DATA_SIDE_INFO = "set-on-subtask-detail-get-data-side-info"
export const SUBTASK_DETAIL_GET_MY_SUBTASK = "set-on-subtask-deatils-get-my-sub-task"
export const SUBTASK_DETAIL_UPDATE_DATA = "set=on-subtask-details-update-data"

export const SUBTASK_DETAIL_CLEAN_UP = "set-on-subtask-details-clean-up"

export const FOOTAGES_UPLOAD_GET_DATA = "set-on-footage-upload-get-data"

export const TABLE_VIEW_DATA_CLEAN_UP = "set-on-table-view-data-clean-up"

export const USER_LIST_DATA_CLEAN_UP = "set-on user-list-data-clean-up"

export const EMPLOYEE_LEAVE_DATA_CLEAN_UP = "set-employee-leave-data-clean-up"
export const EMPLOYEE_APPROVE_LEAVE_DATA_CLEAN_UP = "set-employee-approve-leave-data-clean-up"
export const HR_OFFICE_EXPENSE_DATA_CLEAN_UP = "set-hr-office-expense-data-clean-up"
export const HR_OFFICE_APPROVE_EXPENSE_DATA_CLEAN_UP = "set-hr-office-approve-expense-data-clean-up"
export const GET_ALL_EXPENSESS_DATA_CLEAN_UP = "get_all_expensess_data_clean_up"
export const GET_ALL_OP_EXPENSESS_DATA_CLEAN_UP = "get_all_op_expensess_data_clean_up"
export const SUBTASK_STUDIO_DETAILS = "set-on-subtask-studio-details"
export const SUBTASK_CAMERA_DETAILS = "set-on-subtask-camera-details"
export const GET_HR_EMPLOYEE_LEAVE_DATA = "get-hr-employee-leaves-data"
export const GET_EMPLOYEE_LEAVE_APPROVAL_DATA = "get-leaves-approval-data"
export const GET_HR_OFFICE_EXPENSES_DATA = "get-hr-office-expenses-data"
export const GET_EMPLOYEE_OFFICE_EXPENSES_DATA = "get-employee-office-expenses-data"
export const GET_ALL_OFFICE_EXPENSESS_DATA = "get-all-office-expensess-data"
export const GET_ALL_OP_EXPENSESS_DATA = "get-all-op-expensess-data"
export const ASIN_NUMBER_GET_DATA = "set-asin-number-data"
export const SCRIPT_MODELS_DATA = "get-script-models"
export const AUTOMATED_VO_DATA = "get-automated-vo-data"

export const VENDOR_DATA_CLEAN_UP = "set-vendor-data-clean-up"

export const GET_VENDOR_DATA = "get-vendor-add"

export const GET_VENDOR_NAME = "get-vendor-name"

export const VENDOR_NAME_CLEAN_UP = "set-vendor-name-clean-up"

export const GET_OPERATIONAL_EXPENSES_DATA = "get-operational-expenses-data"

export const OPERATIONAL_EXPENSES_CLEAN_UP = "set-operational-expenses-data-cleanup"

export const GET_OP_EXPENSES_DATA = "get-op-expense-approval-data"

export const OP_APPROVE_EXPENSE_DATA_CLEAN_UP = "set-op-expense-approval-data-cleanup"

export const INFLUENCER_APP_MAPPING_DATA_CLEAN_UP = "set-influencer-app-mapping-data-cleanup"

export const GET_INFLUENCER_APP_MAPPING_DATA = "get-influencer-app-mapping-data"

export const GET_PROJECT_MANAGER_NAME  = "get-project-manager-name-data"

export const PROJECT_MANAGER_NAME_CLEAN_UP = "set-project-manager-name-data-clean-up"