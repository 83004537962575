import {CardContent} from "@mui/material";
import React from 'react';
import ChipDataList from './listItemChip';


const CardContentData = ({data ,stylesx ,check ,boxID,titleZ,checkBOX,dataPayload,cardBoolean,cardBooleanSet}) => {

    let arrayPayload = data;


    function groupBy(array, f) {
        var groups = {};
        array.forEach(function (o) {
            var group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        })
    }

    const updateChipPayload = (data,check1) => {
        
        data.map((itm)=>{
            var exitC = 0;
            
            arrayPayload.map((chip)=>{
                if(chip.key === itm.key){
                    exitC = 1;
                   let index = arrayPayload.findIndex(obj=> obj.key == chip.key)
                   arrayPayload[index].studio = itm.studio;
                   arrayPayload[index].date = itm.date;
                   arrayPayload[index].camera_name = itm.camera_name


                }
            })
            dataPayload(boxID,titleZ,arrayPayload,checkBOX)

            if(exitC == 0)
            {  
                arrayPayload.push({
                    key : itm.key,
                    label : itm.label,
                    flag : itm.flag,
                    studio : itm.studio,
                    date : itm.date,
                    camera_name : itm.camera_name
                })

                dataPayload(boxID,titleZ,arrayPayload,checkBOX)
             }
        })
    }

   
    const deleteFunction = (take_details) => {

        const index = arrayPayload.findIndex((obj)=>obj.key === take_details.key)
       
        if(index > -1)
        {
            arrayPayload.splice(index , 1);
        }
        dataPayload(boxID,titleZ,arrayPayload,checkBOX)
    }
    var result = groupBy(data, function (item) {
        return [item.studio, item.date ,item.camera_name];
    });
//    console.log("result",result);

    return(
        <>
     {result.map((item)=> {
            return (
                
                <CardContent
                    sx={{
                        display: 'flex',
                        minWidth: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: theme => theme.palette.action.hover,
                        ...stylesx,
                    }}
                >
                
                 <ChipDataList
                    data = {item}
                    allData = {data}
                    checkData = {check}
                    studio = {item?.[0]?.studio}
                    date1 ={item?.[0]?.date}
                    boxId = {boxID}
                    title = {titleZ}
                    checkBox = {checkBOX}
                    cameraTake = {item?.[0]?.camera_name}
                   
                    takeDetails = {(a,b,chipDataZ,check1)=>{
                        // console.log("chipdataz---> " , chipDataZ);
                        
                        updateChipPayload(chipDataZ,check1)
                    }}
                    deleteDetails = {(boxId,deleteData) => {
                        console.log("deleteData",deleteData);
                        deleteFunction(deleteData)
                    }}
                 />
                
              </CardContent>

            
        
            )
        })}
        {cardBoolean ?  <CardContent
            sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme => theme.palette.action.hover,
                ...stylesx,
            }}
        >
        
         <ChipDataList
            data = {[]}
            checkData = {check}
            allData = {data}
            studio = {''}
            date = {''}
            cameraTake = {''}
            boxId = {boxID}
            title = {titleZ}
            checkBox = {checkBOX}
            addCardBoolean = {(x) => { 
                console.log("x===",x);
                cardBooleanSet(x);
            }
            }
           
            takeDetails = {(a,b,chipDataZ,c)=>{
                console.log("chipdataz---> " , chipDataZ);
                
                updateChipPayload(chipDataZ)
            }}
            deleteDetails = {(boxId,deleteData) => {
                console.log("deleteData",deleteData);
                deleteFunction(deleteData)
            }}
         />
        
      </CardContent> : null}
      </>
    )
    
    }



export default CardContentData;


