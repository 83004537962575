import React, { useState } from 'react';
// import UserItem from "./UserItem";
import { Button, ButtonGroup, CircularProgress, Typography } from "@mui/material";
import { useEffect } from 'react';
// import { Search, SearchIconWrapper, StyledInputBase } from "./style";
import Div from '@jumbo/shared/Div';
import ApifyClient from 'apify-client';
import { useLocation, useNavigate } from 'react-router-dom';
import AsinItem from './AsinItem';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
import ListIcon from '@mui/icons-material/List';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import AsinNumberTableView from './AsinNumberTableView';

const AsinDetailData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [view, setView] = React.useState("grid");
    const { state } = useLocation();
    const navigate = useNavigate();

    const [asinNumberDetailData, setAsinNumberDetailData] = React.useState([]);

    const onApifyCall = async () => {
        setIsLoading(true)
        try {
            const client = new ApifyClient({
                // token: 'apify_api_teoni6H5Sb9gZysT2QVuRsLjxQ7ngf1p7ezs',
                token: 'apify_api_vXDu584EH0pwNUhoH5pNgNWmdjd5YS2yItgD',
            });
            let rows = state
            let asinUrls = []
            if (rows.length > 0) {
                asinUrls = rows.map((asin) => {
                    // return { 'url': `https://www.amazon.in/dp/${asin.asinData}` }
                    return { 'url': `${asin.asinData}` }
                })
            }
            // // Starts an actor and waits for it to finish.
            // const { defaultDatasetId } = await client.actor('domestic_cradle/revamping-of-queue-concurrent-by-hannah').call({
            const { defaultDatasetId } = await client.actor('tpp/tpp---custom-amazon-product-scrapper-standard').call({
                "start_urls": asinUrls,
                "detailedInformation": false,
                "maxItems": 100,
                "proxyConfiguration": {
                    "useApifyProxy": true
                },
                "useCaptchaSolver": false
            });
            setIsLoading(false)
            // console.log("defaultDatasetId---", defaultDatasetId);
            const { items } = await client.dataset(defaultDatasetId).listItems();
            // console.log("onApifyCall---items", items);
            // const sortedAsinData = [...items].sort((a, b) => {
            //     const asinA = state.find((item) => item.asinData === a.asin);
            //     const asinB = state.find((item) => item.asinData === b.asin);
            //     return asinA.Asin - asinB.Asin;
            // });
            // setAsinNumberDetailData(sortedAsinData)
            const sortedAsinData = state.map((sendingItem) => {
                const receivedItem = items.find(
                    (receivedItem) => receivedItem.url === sendingItem?.asinData
                );
                return receivedItem || sendingItem;
            });
            // console.log("sortedAsinData---", sortedAsinData);
            setAsinNumberDetailData(sortedAsinData)
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            sweetAlerts(error)
        }
        setIsLoading(false)
    }
    const Swal = useSwalWrapper();
    const sweetAlerts = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: message,
        }).then(result => {
            navigate('/asin-data')
        });
    };
    useEffect(() => {
        if (state.length > 0) {
            setIsLoading(true)
            onApifyCall();
        } else { sweetAlerts('Something went wrong!') }
    }, [])
    const handleExport = () => {
        // console.log("asinNumberDetailData----", asinNumberDetailData);
        const convertObjectToArray = (obj) => {
            const stringArray = Object.entries(obj).map(([key, value]) => `${key}: ${value}`);
            return stringArray;
        }
        const mockData = asinNumberDetailData.map((item, index) => {
            let staticColumn = ({
                "id": index + 1,
                "asin": item?.asin ? item?.asin : '-',
                "url": item?.url ? item?.url : "-",
                "productName": item?.productName ? item?.productName : '-',
                "mrp": item?.MRP ? item?.MRP : '-', //no
                "price": item?.price ? item?.price : "-",
                "productImage": item?.productImage ? item?.productImage : "-", //no
                "breadcrumbs": item?.breadcrumbs ? item?.breadcrumbs : "-",
                "brand": item?.brand ? item?.brand : "-",
                "description": item?.description ? item?.description : "-",
                "delivery": item?.delivery ? item?.delivery : "-",
                "stars": item?.star ? item?.star : "-",
                "features0": item?.features?.[0] ? item?.features?.[0] : "-",
                "features1": item?.features?.[1] ? item?.features?.[1] : "-",
                "features2": item?.features?.[2] ? item?.features?.[2] : "-",
                "features3": item?.features?.[3] ? item?.features?.[3] : "-",
                "features4": item?.features?.[4] ? item?.features?.[4] : "-",
                "features5": item?.features?.[5] ? item?.features?.[5] : "-",
                "features6": item?.features?.[6] ? item?.features?.[6] : "-",
                "features7": item?.features?.[7] ? item?.features?.[7] : "-",
                "features8": item?.features?.[8] ? item?.features?.[8] : "-",
                "features9": item?.features?.[9] ? item?.features?.[9] : "-",
                "variations0": item?.variations_0 ? item?.variations_0 : "-",
                "variations1": item?.variations_1 ? item?.variations_1 : "-",
                "variations2": item?.variations_2 ? item?.variations_2 : "-",
                "variations3": item?.variations_3 ? item?.variations_3 : "-",
                "variations4": item?.variations_4 ? item?.variations_4 : "-",
                "variations5": item?.variations_5 ? item?.variations_5 : "-",
                "variations6": item?.variations_6 ? item?.variations_6 : "-",
                "variations7": item?.variations_7 ? item?.variations_7 : "-",
                "variations8": item?.variations_8 ? item?.variations_8 : "-",
                "variations9": item?.variations_9 ? item?.variations_9 : "-",
                // "features": item?.features.map((feat) => { return feat + "||" }),
                // "variations": item?.variantAsins,
                "seller": item?.seller ? item?.seller : "-",
                "image0": item?.allImages?.[0] ? item?.allImages?.[0] : "-",
                "image1": item?.allImages?.[1] ? item?.allImages?.[1] : "-",
                "image2": item?.allImages?.[2] ? item?.allImages?.[2] : "-",
                "image3": item?.allImages?.[3] ? item?.allImages?.[3] : "-",
                "image4": item?.allImages?.[4] ? item?.allImages?.[4] : "-",
                "image5": item?.allImages?.[5] ? item?.allImages?.[5] : "-",
                "image6": item?.allImages?.[6] ? item?.allImages?.[6] : "-",
                "image7": item?.allImages?.[7] ? item?.allImages?.[7] : "-",
                "image8": item?.allImages?.[8] ? item?.allImages?.[8] : "-",
                "image9": item?.allImages?.[9] ? item?.allImages?.[9] : "-",
                "video0": item?.allVideos?.[0] ? item?.allVideos?.[0] : "-",
                "video1": item?.allVideos?.[1] ? item?.allVideos?.[1] : "-",
                "video2": item?.allVideos?.[2] ? item?.allVideos?.[2] : "-",
                "video3": item?.allVideos?.[3] ? item?.allVideos?.[3] : "-",
                "video4": item?.allVideos?.[4] ? item?.allVideos?.[4] : "-",
                "aPlusMainText": item?.aPlusMainText ? item?.aPlusMainText : "-",
                "aPlusMainImages": item?.aPlusMainImages ? item?.aPlusMainImages : "-",
                "aPlusParagraph": item?.aPlusParagraph ? item?.aPlusParagraph : "-",
                "aPlusImages": item?.aPlusImages ? item?.aPlusImages : "-",
                "whatsInTheBox": item?.whatsInTheBox ? item?.whatsInTheBox : "-",
                "offers": item?.offers ? item?.offers : "-",
                "productBadges": item?.productBadge ? item?.productBadge : "-",
                "productInfo": item?.productInformation ? convertObjectToArray(item?.productInformation) : "-",
            })

            // const concatenatedObj = Object.assign({}, staticColumn, featureObj);
            // console.log("concatenatedObj---", concatenatedObj);
            return staticColumn;
        })
        function convertToCSV(objArray) {
            var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
            var str = '';
            for (var i = 0; i < array.length; i++) {
                var line = '';
                for (var index in array[i]) {
                    if (line != '') line += '++'
                    let newValue = '';
                    if (typeof (array[i][index]) === 'string' && array[i][index].includes('"')) {
                        newValue = array[i][index].replace(/"/g, '”');
                    } else {
                        newValue = array[i][index]
                    }
                    line += newValue;
                    // line += array[i][index];
                }
                const convertedSentence = line.split("++").map(word => `"${word}"`).join(",");
                str += convertedSentence + '\r\n';
            }
            return str;
        }

        function exportCSVFile(headers, items, fileTitle) {
            if (headers) {
                items.unshift(headers);
            }
            // Convert Object to JSON
            var jsonObject = JSON.stringify(items);
            var csv = convertToCSV(jsonObject);

            var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

            var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) { // IE 10+
                navigator.msSaveBlob(blob, exportedFilenmae);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", exportedFilenmae);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        }

        var headers = {
            id: "ID",
            asin: "ASIN",
            url: "URL",
            productName: "Product Name",
            mrp: "MRP",
            price: "Price",
            productImage: "Product Image",
            breadcrumbs: "Breadcrumbs",
            brand: "Brand",
            description: "Description",
            delivery: "Delivery",
            stars: "Stars",
            features0: "Features1",
            features1: "Features2",
            features2: "Features3",
            features3: "Features4",
            features4: "Features5",
            features5: "Features6",
            features6: "Features7",
            features7: "Features8",
            features8: "Features9",
            features9: "Features10",
            variations0: "Variations 1",
            variations1: "Variations 2",
            variations2: "Variations 3",
            variations3: "Variations 4",
            variations4: "Variations 5",
            variations5: "Variations 6",
            variations6: "Variations 7",
            variations7: "Variations 8",
            variations8: "Variations 9",
            variations9: "Variations 10",
            seller: "Seller",
            image0: "Item Image1",
            image1: "Item Image2",
            image2: "Item Image3",
            image3: "Item Image4",
            image4: "Item Image5",
            image5: "Item Image6",
            image6: "Item Image7",
            image7: "Item Image8",
            image8: "Item Image9",
            image9: "Item Image10",
            video0: "Video 1",
            video1: "Video 2",
            video2: "Video 3",
            video3: "Video 4",
            video4: "Video 5",
            aPlusMainText: "A+ Main Text",
            aPlusMainImages: "A+ Main Images",
            aPlusParagraph: "A+ Paragraph",
            aPlusImages: "A+ Images",
            whatsInTheBox: "Whats In The Box",
            offers: "Offers",
            productBadges: "Product Badges",
            productInfo: "Product Information",
        };
        var fileTitle = 'Asin Data Export'; // or 'my-unique-title'
        exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
        // exportCSVFile(headers, sortedAsinData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    }

    return (
        <React.Fragment>
            <Typography variant={'h2'} mb={3}>Card/Table View</Typography>
            <ButtonGroup
                variant="outlined"
                disableElevation
                sx={{
                    '& .MuiButton-root': {
                        px: 1,
                    }
                }}
            >
                <Button variant={view === "grid" ? "contained" : "outlined"}
                    onClick={() => setView("grid")}><ViewComfyIcon /></Button>
                <Button variant={view === "list" ? "contained" : "outlined"}
                    onClick={() => setView("table")}><ListIcon /></Button>
            </ButtonGroup>
            <div style={{ height: '70px', width: '100%' }}>
                <Button sx={{ float: 'right' }} variant="contained" onClick={handleExport}>
                    Export CSV
                </Button>
            </div>
            {isLoading ?
                <Div sx={{ flexGrow: 1 }}>
                    <CircularProgress color='primary' sx={{ marginLeft: '50%', marginTop: '20%' }} />
                    <Typography sx={{ marginLeft: '25%' }}>
                        <span>The Actor is getting your data...</span>
                        <span>it usually takes around 2-3 minutes to start seeing results</span>
                    </Typography>
                </Div>

                :
                <>
                    {
                        view === "grid" ? (
                            asinNumberDetailData.map((asin, index) =>
                                <AsinItem asinItem={asin} key={index} />
                            )) : (
                            <AsinNumberTableView asinNumberTableData={asinNumberDetailData} />
                        )
                    }
                </>
            }
        </React.Fragment>
    );
};

export default AsinDetailData;
