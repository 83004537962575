import React from 'react';
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import {Avatar, Card, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Camera from '@mui/icons-material/Camera';
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import moment from 'moment';
import { Link} from 'react-router-dom';

const Item = styled(Span)(({theme}) => ({
    padding: theme.spacing(0, 1),
}));

const UserItem = ({user}) => {

     var url = "/footages/detailedView?subTaskId="+user?._id
     var amazonUrl = "https://www.amazon.in/dp/"+user?.asin
    return (
        
        <Card sx={{mb: 1}}>
            <Stack direction={"row"} alignItems={"center"} sx={{p: theme => theme.spacing(2, 1)}}>
                <Item
                    sx={{
                        flex: {xs: 1, md: '0 1 45%', lg: '0 1 35%'}
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        
                        <Item>
                            <Badge overlap="circular" variant="dot"
                                   anchorOrigin={{
                                       vertical: 'bottom',
                                       horizontal: 'right',
                                   }}
                                
                            >
                                
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56
                                    }}
                                    alt={'Path Not Pound'}
                                    src={`https://ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=IN&ASIN=${user?.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL80`}
                                />  
                            </Badge>
                        </Item>
                        <Item>
                        <a href={amazonUrl} target="_blank"><Typography variant={"h6"} mb={.5}>{`${user?.asin}`}</Typography></a>
                            <Typography variant={"body1"} color="text.secondary" fontSize={13} textOverflow ={'clip'}>{user?.product_name}</Typography>
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                        alignSelf: 'flex-start',
                        flexBasis: {md: '28%', lg: '18%'},
                        display: {xs: 'none', md: 'block'}
                    }}
                >   
                    <Typography></Typography>
                    <Typography variant={"h6"} mt={1} lineHeight={1.25} color={(user?.work_status == "Submitted" ? "pink" : (user?.work_status == "InProgress" ? "primary" : (user?.work_status == "Approved" ? "green" : "error")))}>{user?.work_status}</Typography>
                    
                </Item>
                
                <Item
                    sx={{
                        flexBasis: '30%',
                        display: {xs: 'none', lg: 'block'}
                    }}
                >
                    <Stack spacing={2} direction={"row"} alignItems={"center"} sx={{textAlign: 'center'}}>
                        
                    <Item>
                            <Typography variant={"h6"} mb={.5}>{moment(user?.expected_delivery_date).format('DD MMM YYYY')}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Exp. Delivery Date</Typography>
                        </Item>

                        <Item>
                            <Typography variant={"h6"} mb={.5}>{moment(user?.filming_date).format('DD MMM YYYY')}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Filming Date</Typography>
                        </Item>
                        
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{user?.video_code}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Video Code</Typography>
                        </Item>
                       
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{user?.product_rating}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Product Rating</Typography>
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                        ml: 'auto',
                        display: {xs: 'none', sm: 'block'}
                    }}
                >
                    <Button sx={{minWidth: 92}} disableElevation variant={"contained"} size={"small"}
                            color={user?.product_status == "Arrived" ? "success" : "error"}>
                        {user?.product_status}
                    </Button>
                </Item>
                
                <Item sx={{ml: {xs: 'auto', sm: 0}}}>
                
                    <IconButton aria-label="settings">
                        
                       <Link to={url}> <ArrowForwardIos/></Link>
                       
                    </IconButton>
                    
                </Item>
               
            </Stack>
        </Card>
       
    );
};

export default UserItem;
