import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import ArticleIcon from '@mui/icons-material/Article';
import { Button, Pagination } from '@mui/material';
// import { DataGrid} from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { OperationalExpensesCleanUp, operationalExpenses } from 'app/redux/actions/subTaskAction';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import ExpenseDocumentBox from './ExpenseDocumentBox';
import OperationalExpenseForm from './OperationalExpenseForm';
import OpPaymentStatusForm from './OpPaymentStatusForm'; ///home/digiflux-n3/zikhara-vua-ui/src/app/pages/HrOfficeExpanses/PaymentStatusForm.js
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';


export default function OperationalExpense() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();
  const roleCode = localStorage.getItem("roleCode");

  const { operationalExpensesData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  // const [paymentStatus, setPaymentStatus] = React.useState('UnPaid');
  const [paymentDate, setPaymentDate] = React.useState(new Date().toISOString().split('T')[0]);
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);

  var _id = pageId;

  const rows = operationalExpensesData.map((item) => {
    //console.log('item',item?.payment_status)
    return (
      {
        id: _id++,
        vendorName: item?.vendor_id?.vendor_name,
        tdsPercent: item?.vendor_id?.tds_amount,
        expenseId: item?.id,
        expenseAmount: item?.expense_amount,
        expenseDate: item?.expense_date ? moment(item?.expense_date).format('DD MMM YYYY') : '',
        expenseDescription: item?.expense_description || '--',
        expenseNote: item?.expense_note || '--',
        expenseDoc: item?.bill_reciept_documents || '--',
        status: item?.status || 'Requested',
        hrFinanceStatus: item?.hr_finance_status || '--',
        paymentStatus: item?.payment_status || 'Unpaid',
        paymentDate : item?.payment_date ? moment(item?.payment_date).format('DD MMM YYYY') : '',
        actionBy: item?.action_by ? item?.action_by.name : '--',
        addedBy: item?.created_by ? item?.created_by.name : '--',
        actionRemark: item?.action_remark || '--',
        sgstAmount: item?.sgst_amount || '--',
        cgstAmount: item?.cgst_amount || '--',
        igstAmount: item?.igst_amount || '--',
        tdsAmount: item?.tds_amount || '--',
        totalGST: item?.total_gst || '--',
        amountWithTax: item?.amount_with_tax || '--',
        payableAmount: item?.payable_amount | '--',
        invoiceNumber: item?.invoice_number || '--',
        

      }
    )
  })

  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }
  const handlePaymentStatusUpdate = (newPaymentDate) => {
    setPaymentDate(newPaymentDate);
  };
  const currentDate = new Date();
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            {/* <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => showAddLeaveDialog(params.row.expenseId)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
            </NavLink> */}
            <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => {
                                    if (params.row.status === 'Cancelled' || params.row.status ==='Approved' || params.row.status ==='Rejected') {
                                        
                                     return;

                                        }
                                        else{
                                          showAddLeaveDialog(params.row.expenseId);

                                        }
                                    } 
                                }
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    cursor: ((params.row.status !== 'Cancelled'|| params.row.status !=='Approved' || params.row.status !=='Rejected')) ? 'pointer' : 'not-allowed'
                                }}
                                color="action"
                                disabled={(params.row.status === 'Cancelled'|| params.row.status ==='Approved' || params.row.status ==='Rejected')} />
            </NavLink>
            {/* <NavLink>
              <DeleteIcon onClick={() => cancleExpenseRequest(params.row.expenseId)} style={{ width: '15px', height: "15px", marginLeft: "2px" }} color="action" />
            </NavLink> */}
            <NavLink>
                            <DeleteIcon
                                onClick={() => {
                                    if (params.row.status !== 'Requested') {
                                        
                                     return;

                                        }
                                        else{
                                          cancleExpenseRequest(params.row.expenseId);

                                        }
                                    } 
                                }
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    cursor: ((params.row.status === 'Requested')) ? 'pointer' : 'not-allowed'
                                }}
                                color="action"
                                disabled={!((params.row.status === 'Requested'))}
                            />
                        </NavLink>
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>ID</span>
        </div>
      ),
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: false,
      // filterable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Vendor Name</span>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Manager Status',
      sortable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center', renderCell: (params) => {
        let chipColor = 'green'; // Default color
        switch (params.value) {
          case 'Requested':
            chipColor = 'blue';
            break;
          case 'Approved':
            chipColor = 'green';
            break;
          case 'Rejected':
            chipColor = 'red';
            break;
          default:
            chipColor = 'gray'; // Handle other statuses here
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 10, // Adjust the width of the chip as needed
                height: 10, // Adjust the height of the chip as needed
                backgroundColor: chipColor, // Set chip color based on status
                marginRight: 8, // Adjust the spacing between the chip and text
                borderRadius: '100%', // To make it a circle
              }}
            ></div>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Manager Status</span>
        </div>
      ),
    },
    // {
    //   field: 'hrFinanceStatus',
    //   headerName: 'HR/Finance Status',
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center', renderCell: (params) => {
    //     let chipColor = 'green'; // Default color
    //     switch (params.value) {
    //       case 'Requested':
    //         chipColor = 'blue';
    //         break;
    //       case 'Approved':
    //         chipColor = 'green';
    //         break;
    //       case 'Rejected':
    //         chipColor = 'red';
    //         break;
    //       default:
    //         chipColor = 'gray'; // Handle other statuses here
    //     }

    //     return (
    //       <div style={{ display: 'flex', alignItems: 'center' }}>
    //         <div
    //           style={{
    //             width: 10, // Adjust the width of the chip as needed
    //             height: 10, // Adjust the height of the chip as needed
    //             backgroundColor: chipColor, // Set chip color based on status
    //             marginRight: 8, // Adjust the spacing between the chip and text
    //             borderRadius: '100%', // To make it a circle
    //           }}
    //         ></div>
    //         {params.value}
    //       </div>
    //     );
    //   },
    //   renderHeader: (params) => (
    //     <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
    //       <span>HR/Finance Status</span>
    //     </div>
    //   ),
    // },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice No.',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Invoice No.</span>
        </div>
      ),
    },
    {
      field: 'addedBy',
      headerName: 'Added By',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      headerAlign: 'center', renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Added By</span>
        </div>
      ),
    },
    {
      field: 'actionRemark',
      headerName: 'Remark',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Remark</span>
        </div>
      ),
    },
    {
      field: 'actionBy',
      headerName: 'Approved By',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Approved By</span>
        </div>
      ),
    },

    {
      field: 'expenseAmount',
      headerName: 'Expense Amount',
      sortable: false,
      // filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Expense Amount</span>
        </div>
      ),
    },
    {
      field: 'expenseDate',
      headerName: 'Expense Date',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Expense Date</span>
        </div>
      ),
    },
    {
      field: 'expenseDescription',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Description</span>
        </div>
      ),
    },
    {
      field: 'expenseNote',
      headerName: 'Note',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Note</span>
        </div>
      ),
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      // filterable: false,
      type:'singleSelect',
      valueOptions: ['Paid','Unpaid'],
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
//       renderCell: (params) => (
//         <div style={{ display: 'flex', alignItems: 'center' }}>
// <Button
//         size="small"
//         onClick={
//           params.row.hrFinanceStatus === 'Approved' && roleCode === 'SUPERADMIN' || roleCode === 'FINANCEMANAGER' || roleCode === 'ACCOUNTSMANAGER'
//             ? () => showAddLeaveDialogPayment(
//                 params.row.expenseId,
//                 params.row.paymentStatus,
//                 params.row.paymentDate,
//                 params.row.hrFinanceStatus
//               )
//             : undefined // Or any other function to handle non-approved status
//         }
//         disabled={params.row.hrFinanceStatus !== 'Approved'}
//       >
//         {params.value}
//       </Button>
     
//              </div>
//       ),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Status</span>
        </div>
      ),
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      // filterable: false,
      type: 'date',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
     
      valueGetter: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) { // Check if date is invalid
          return ''; // Return current date if invalid
        }
        return date; // Return the parsed date if valid
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Date</span>
        </div>
      ),
    },
    {
      field: 'tdsPercent',
      headerName: 'TDS Percent',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>TDS Percent</span>
        </div>
      ),
    },
    {
      field: 'tdsAmount',
      headerName: 'TDS Amount',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>TDS Amount</span>
        </div>
      ),
    },
    {
      field: 'totalGST',
      headerName: 'Total GST',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Total GST</span>
        </div>
      ),
    },
    {
      field: 'amountWithTax',
      headerName: 'Amount with tax',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Amount with tax</span>
        </div>
      ),
    },
    {
      field: 'payableAmount',
      headerName: 'Payable Amount',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payable Amount</span>
        </div>
      ),
    
    },
    {
      field: 'expenseDoc',
      headerName: 'Documents',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          //   <ArticleIcon onClick={() => showExpenseDialog(params.value)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
          // </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {params.row.expenseDoc.length > 0 && (
              <ArticleIcon
                onClick={() => showExpenseDialog(params.value)}
                style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                color="action"
              />
            )}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Documents</span>
        </div>
      ),
    },
  ];
  const sweetAlerts = (variant, message,callback) => {
    Swal.fire({
      icon: variant,
      title: variant === "success" ? 'Success' : 'Warning',
      text: message,
      showCancelButton: variant !== "success", // Show cancel button for non-success alerts
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed && callback) {
        callback();
        // window.location.reload() // Perform action if confirmed
      }
    });
  
  };
  const showExpenseDialog = (expenseDoc) => {
    showDialog({
      title: "Expense Documents",
      content: <ExpenseDocumentBox expenseDoc={expenseDoc} />
    });
  }
 
  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    // if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

    //   filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    // } else {
    //   filterString = ''
    // }
    if (queryOptions?.filterModel?.items) {
      filterString = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }else {
      filterString = ''
    }
    subTaskService.getOperationalExpenses(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(operationalExpenses(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(OperationalExpensesCleanUp())
    }
  }, [offset, queryOptions])
  const handleLeaveAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialog = React.useCallback((expanseId) => {
    showDialog({
      title: "Add Expense",
      content: <OperationalExpenseForm expanseId={expanseId} onSave={handleLeaveAdd} />
    });
  }, [handleLeaveAdd, showDialog]);
  const handlePaymentUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialogPayment = React.useCallback((expanseId, paymentStatus, paymentDate, hrFinanceStatus) => {
    showDialog({
      title: "Add Payment Status",
      content: <OpPaymentStatusForm expanseId={expanseId} paymentStatus={paymentStatus} paymentDate={paymentDate} hrFinanceStatus={hrFinanceStatus} onSave={handlePaymentUpdate} />
    });
  }, [handlePaymentUpdate, showDialog]);
  const cancleExpenseRequest = (expanseId) => {
    sweetAlerts("warning", "Are you sure you want to cancel this expense?", () => {
      let payload = {
            status: "Cancelled",
          }
      subTaskService.updateOpExpenseStatus(payload,expanseId)
        .then((data) => {
          //console.log('Cancelled');
          hideDialog();
          sweetAlerts('success', 'Expense Cancelled Successfully!', () => {
            window.location.reload();
          });
        })
        .catch((err) => {
          hideDialog();
          if (err?.response?.status == 401) {
            sweetAlerts('error', 'Failed to Cancel expense');
          }
        });
    });
  }
  const handleCellClick = (params, event) => {
    const columnId = params.field; // Get the value of the clicked cell
    //console.log('params.field',params.field)
    if (columnId != 'id' && columnId != '__check__' && columnId != 'expenseDoc') {
      const cellValue = params.value; // Get the value of the clicked cell

      Swal.fire({
          // title: 'Cell Content',
          html: `<div style="white-space: pre-wrap;">${cellValue}</div>`,
          // showCloseButton: true,
          showConfirmButton: false,
          customClass: {
              popup: 'my-custom-size', // Apply custom class to the dialog box
          },
          didOpen: () => {
              const swalPopup = Swal.getPopup();
              if (swalPopup) {
                  swalPopup.style.width = '400px'; // Modify the width as needed
              }
          },
      });
  }
};
  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
        <Button sx={{ float: 'right' }}
  variant="contained"
  onClick={()=>showAddLeaveDialog()}
  // disabled={roleCode === 'HR' || roleCode === 'FINANCEMANAGER'}
  >
          Add Expense
        </Button>

      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGridPro sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}
          onCellClick={(params, event) => handleCellClick(params, event)}
        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}