import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from '@mui/icons-material/Edit';
import { Button, Pagination } from '@mui/material';
// import { DataGrid} from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ExpenseDocumentBox from '../HrOfficeExpanses/ExpenseDocumentBox';
import ExpenseApprovalFrom from './ExpenseApprovalFrom';
import { expenseApprovalData, hrOfficeApproveExpenseCleanUp } from 'app/redux/actions/subTaskAction';
import Swal from 'sweetalert2';
import { docBaseUrl } from 'app/services/config';
import { useEffect, useState } from 'react';

export default function EmployeeExpenseApprove() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();
  const roleCode = localStorage.getItem("roleCode");
  const { employeeOfficeExpensesData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  var _id = pageId;
  const rows = employeeOfficeExpensesData.map((item) => {
    return (
      {
        id: _id++,
        employeeName: item?.employee_id.name,
        expenseId: item?.id,
        expenseAmount: item?.expense_amount,
        expenseDate: item?.expense_date ? moment(item?.expense_date).format('DD MMM YYYY') : '',
        expenseDescription: item?.expense_description || '--',
        expenseNote: item?.expense_note || '--',
        expenseDoc: item?.bill_reciept_documents || '--',
        status: item?.status || 'Requested',
        hrFinanceStatus: item?.hr_finance_status || '--',
        paymentStatus: item?.payment_status || 'Unpaid',
        paidBy:item?.paid_by || '--',
        paymentDate: item?.payment_date ? moment(item?.payment_date).format('DD MMM YYYY') : '',
        actionRemark: item?.action_remark || '--',
        actionBy: item?.action_by ? item?.action_by.name : '--',
        hrActionBy: item?.hr_action_by ? item?.hr_action_by.name : '--',

      }
    )
  })
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            {/* <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => showApproveExpenseDialog(params.row.expenseId)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
            </NavLink> */}
            <NavLink>
              <EditIcon
                onClick={() => {
                  // //console.log('should not1', params.row)

                  if (params.row.status === 'Requested' ) {
                    // //console.log('should not')
                    
                    showApproveExpenseDialog(params.row.expenseId, params.row.hrFinanceStatus);
                  

                  }
                  else {
                    return;
                  }
                  //   if (params.row.hrFinanceStatus === 'Requested') {
                  //     //console.log('should not')
                  //     if (roleCode === 'HR' || roleCode === 'FINANCEMANAGER') {
                  //         showApproveExpenseDialog(params.row.expenseId, params.row.hrFinanceStatus);
                  //     }

                  // } 
                  // else if(params.row.hrFinanceStatus === 'Approved'|| params.row.status === 'Cancelled' || params.row.status === 'Rejected'){
                  //     return;
                  // }
                  // // else if (roleCode === 'HR'|| roleCode === 'FINANCEMANAGER') {
                  // //     return; // Don't open dialog for 'HR' if status is not 'Requested'
                  // // }
                  // else {
                  //     showApproveExpenseDialog(params.row.expenseId, params.row.hrFinanceStatus);
                  // }
                }}
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '5px',
                  cursor: ((params.row.hrFinanceStatus != 'Requested') && roleCode === 'HR' || roleCode === 'FINANCEMANAGER') ? 'pointer' : 'not-allowed'
                }}
                color="action"
                disabled={!((params.row.hrFinanceStatus != 'Requested') && roleCode === 'HR' || roleCode === 'FINANCEMANAGER')}
              />
            </NavLink>
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>ID</span>
        </div>
      ),
    },
    {
      field: 'employeeName',
      headerName: 'Empoyee Name',
      sortable: false,
      // filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Employee Name</span>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Manager Status',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center', renderCell: (params) => {
        let chipColor = 'green'; // Default color
        switch (params.value) {
          case 'Requested':
            chipColor = 'blue';
            break;
          case 'Approved':
            chipColor = 'green';
            break;
          case 'Rejected':
            chipColor = 'red';
            break;
          default:
            chipColor = 'gray'; // Handle other statuses here
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 10, // Adjust the width of the chip as needed
                height: 10, // Adjust the height of the chip as needed
                backgroundColor: chipColor, // Set chip color based on status
                marginRight: 8, // Adjust the spacing between the chip and text
                borderRadius: '100%', // To make it a circle
              }}
            ></div>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Manager Status</span>
        </div>
      ),
    },
    {
      field: 'hrFinanceStatus',
      headerName: 'HR/Finance Status',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center', renderCell: (params) => {
        let chipColor = 'green'; // Default color
        switch (params.value) {
          case 'Requested':
            chipColor = 'blue';
            break;
          case 'Approved':
            chipColor = 'green';
            break;
          case 'Rejected':
            chipColor = 'red';
            break;
          default:
            chipColor = 'gray'; // Handle other statuses here
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 10, // Adjust the width of the chip as needed
                height: 10, // Adjust the height of the chip as needed
                backgroundColor: chipColor, // Set chip color based on status
                marginRight: 8, // Adjust the spacing between the chip and text
                borderRadius: '100%', // To make it a circle
              }}
            ></div>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>HR/Finance Status</span>
        </div>
      ),
    },

    {
      field: 'actionRemark',
      headerName: 'Remark',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Remark</span>
        </div>
      ),
    },
    {
      field: 'expenseAmount',
      headerName: 'Expense Amount',
      sortable: false,
      // filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      headerAlign: 'center', renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Expense Amount</span>
        </div>

      ),
    },
    {
      field: 'expenseDate',
      headerName: 'Expense Date',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Expense Date</span>
        </div>
      ),
    },
    {
      field: 'expenseDescription',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Description</span>
        </div>
      ),
    },
    {
      field: 'expenseNote',
      headerName: 'Note',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Note</span>
        </div>
      ),
    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      // filterable: false,
      type: 'singleSelect',
      valueOptions: ['Paid', 'Unpaid'],
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      // renderCell: (params) => (
      //   <div style={{ display: 'flex', alignItems: 'center' }}>
      //     <Button
      //       size="small"
      //       onClick={
      //         params.row.status === 'Approved'
      //           ? () => showAddLeaveDialogPayment(
      //             params.row.expenseId,
      //             params.row.paymentStatus,
      //             params.row.paymentDate,
      //             params.row.status
      //           )
      //           : undefined // Or any other function to handle non-approved status
      //       }
      //       disabled={params.row.status !== 'Approved'}
      //     >
      //       {params.value}
      //     </Button>        </div>
      // ),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Status</span>
        </div>
      ),
    },
    // {
    //   field: 'paymentDate',
    //   headerName: 'Payment Date',
    //   // filterable: false,
    //   // type: 'date',
    //   // sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center',
    //   // valueGetter: () => {

    //   //   // // Format the current date as 'YYYY-MM-DD'
    //   //   // const formattedDate = currentDate.toISOString().split('T')[0];
    //   //   return paymentDate;
    //   // },
    //   renderHeader: (params) => (
    //     <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
    //       <span>Payment Date</span>
    //     </div>
    //   ),
    // },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      // filterable: false,
      type: 'date',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
     
      valueGetter: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) { // Check if date is invalid
          return ''; // Return current date if invalid
        }
        return date; // Return the parsed date if valid
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Date</span>
        </div>
      ),
    },
    {
      field: 'expenseDoc',
      headerName: 'Documents',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          //   <ArticleIcon onClick={() => showExpenseDialog(params.value)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
          // </div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {params.row.expenseDoc.length > 0 && (
              <ArticleIcon
                onClick={() => showExpenseDialog(params.value)}
                style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                color="action"
              />
            )}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Documents</span>
        </div>
      ),
    },
  ];
  const showExpenseDialog = (expenseDoc) => {
    showDialog({
      title: "Expense Documents",
      content: <ExpenseDocumentBox expenseDoc={expenseDoc} />
    });
  }
  const handleExpenseUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showApproveExpenseDialog = React.useCallback((expenseIds, hrFinanceStatus) => {
    showDialog({
      title: "Expense",
      content: <ExpenseApprovalFrom expenseIds={expenseIds} hrFinanceStatus={hrFinanceStatus} onSave={handleExpenseUpdate} />
    });
  }, [handleExpenseUpdate, showDialog]);
  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }

  const handleExport = () => {
    var filterQuery;
    // if (queryOptions?.filterModel?.items?.[0]?.field != undefined) {
    //   filterQuery = `&${queryOptions?.filterModel?.items?.[0]?.field}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`
    // }
    // else {
    //   filterQuery = ''
    // }

    if (queryOptions?.filterModel?.items) {
      filterQuery = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }
  else {
      filterQuery = ''
    }

    subTaskService.getExpensesApprovals('EmployeeExpenseApprove', filterQuery).then((data) => {
      // //console.log('log fro csv');

      const mockData = data?.data?.data?.map((item, index) => {
        return ({
          "id": index + 1,
          "employeeName": item?.employee_id.name || '--',
          "status": item?.status || 'Requested',
          "hrFinanceStatus": item?.hr_finance_status || '--',
          "actionRemark": item?.action_remark || "--",
          "actionBy": item?.action_by ? item?.action_by.name : '--',
          "hrActionBy": item?.hr_action_by ? item?.hr_action_by.name : '--',
          "paymentStatus": item?.payment_status || 'Unpaid',
          "paidBy":item?.paid_by || '--',
          "paymentDate": item?.payment_date ? moment(item?.payment_date).format('DD MMM YYYY') : '',
          "expenseAmount": item?.expense_amount || '--',
          "expenseDate": moment(item?.expense_date).format('DD MMM YYYY') || '--',
          "expenseDescription": item?.expense_description || '--',
          "expenseNote": item?.expense_note || '--',
          "expenseDoc": item?.bill_reciept_documents.map((doc) => {
            return docBaseUrl + doc?.base_url + "/" + doc?.key
            // return docBaseUrl + doc?.base_url + "/" + doc?.key
          }) || '--',
        })
      })

      function convertToCSV(objArray) {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var csv = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !== '') line += ',';
                var fieldValue = array[i][index];
                
                // Check if fieldValue is an array
                if (Array.isArray(fieldValue)) {
                    // Join array elements with newline characters
                    fieldValue = fieldValue.join('\n');
                } else if (typeof fieldValue === 'string' && fieldValue.charAt(0) === '0') {
                    // Check if fieldValue starts with '0'
                    // Wrap fieldValue in double quotes to preserve leading zeros
                    fieldValue = '"' + fieldValue + '"';
                }
                
                // Check if fieldValue is a string and contains special characters
                if (typeof fieldValue === 'string' && (fieldValue.includes(',') || fieldValue.includes('\n'))) {
                    // Wrap fieldValue in double quotes and escape existing double quotes
                    fieldValue = '"' + fieldValue.replace(/"/g, '""') + '"';
                }
    
                line += fieldValue;
            }
            csv += line + '\r\n';
        }
    
        return csv;
    }

      function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
          items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

      var headers = {

        id: "ID",
        employeeName: "Employee Name",
        status: "Manager Status",
        hrFinanceStatus: "HR/Finance Status",
        actionRemark: "Remark",
        actionBy: "Approved By(Manager)",
        hrActionBy: "Approved By(hr/finance)",
        paymentStatus: "Payment Status",
        paidBy:"Paid From",
        paymentDate: "Payment Date",
        expenseAmount: "Expense Amount",
        expenseDate: "Expense Date",
        expenseDescription: "Description",
        expenseNote: "Note",
        expenseDoc: "Documents"
      };

      var fileTitle = 'Employee Expense Export'; // or 'my-unique-title'



      exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download


    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
  }

  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    // //console.log('queryOptions',queryOptions)
    // if (queryOptions?.filterModel?.items?.[0]?.field != undefined) {

    //   filterString = `&${queryOptions?.filterModel?.items?.[0]?.field}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    // } else {
    //   filterString = ''
    // }
    if (queryOptions?.filterModel?.items) {
      filterString = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }else {
      filterString = ''
    }

  // //console.log('filterStrings:', filterString);
    subTaskService.getExpensesApprovals('EmployeeExpenseApprove', offset, limit, filterString,).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(expenseApprovalData(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(hrOfficeApproveExpenseCleanUp())
    }
    // subTaskService.subTaskGetMyFootageUploadData(filterString).then((data)=>{
    //   dispatch(uploadBatchExport(data?.data))

    // }).catch((err) => {
    //   if (err?.response?.status == 401) {
    //     setAuthToken(null);
    //   }
    // })

  }, [offset, queryOptions])
  const handleLeaveAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialog = React.useCallback(() => {
    showDialog({
      title: "Update Expense",
      //   content: <OfficeExpenseForm onSave={handleLeaveAdd} />
    });
  }, [handleLeaveAdd, showDialog]);

  const handleBulkApproval = () => {
    // //console.log('')
    // const invoices =  rowSelectionModel.map(itm=> {return rows[itm-1]} );
    const expenseIds = rowSelectionModel?.map(itm => itm.expenseId);
    // rows?.filter((itm,idx)=>rowSelectionModel?.includes()).map(itm=>)
    showApproveExpenseDialog(expenseIds)
  }
  const handleFinanceBulkApproval = () => {
    // //console.log('')
    // const invoices =  rowSelectionModel.map(itm=> {return rows[itm-1]} );
    const expenseIds = rowSelectionModel?.map(itm => itm.expenseId);
    // rows?.filter((itm,idx)=>rowSelectionModel?.includes()).map(itm=>)
    showApproveExpenseDialog(expenseIds, 'Requested')
  }

  const handleCellClick = (params, event) => {
    const columnId = params.field; // Get the value of the clicked cell
    // //console.log('params.field', params.field)
    if (columnId != 'id' && columnId != '__check__' && columnId != 'expenseDoc') {
      const cellValue = params.value; // Get the value of the clicked cell

      Swal.fire({
        // title: 'Cell Content',
        html: `<div style="white-space: pre-wrap;">${cellValue}</div>`,
        // showCloseButton: true,
        showConfirmButton: false,
        customClass: {
          popup: 'my-custom-size', // Apply custom class to the dialog box
        },
        didOpen: () => {
          const swalPopup = Swal.getPopup();
          if (swalPopup) {
            swalPopup.style.width = '400px'; // Modify the width as needed
          }
        },
      });
    }
  };



  return (
    <>
      Employee Expense
      <div style={{ height: '60px', width: '100%' }}>
      { rowSelectionModel.length > 0 && (
        <Button variant="contained" onClick={handleBulkApproval}>
          Approve/Reject
        </Button>
      )}
      {/* {roleCode === 'FINANCEMANAGER' && rowSelectionModel.length > 0 && (
        <Button variant="contained" sx={{marginLeft:'5px'}} onClick={handleFinanceBulkApproval}>
          Approve/Reject Finance
        </Button>
      )} */}
        <Button onClick={handleExport} sx={{ float: 'right' }} variant="contained" color='secondary'> 
          Export Expenses
        </Button>

      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGridPro checkboxSelection isRowSelectable={(params) => params.row.status != "Approved" && params.row.status != "Rejected" && params.row.status != "Cancelled"} sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
        
          hideFooter={true}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            const selectedRowData = newRowSelectionModel.map(itm => { return rows[itm - 1] });
            setRowSelectionModel(selectedRowData);
          }}

          rowSelectionModel={rowSelectionModel?.map(i => i.id)}
          onCellClick={(params, event) => handleCellClick(params, event)}
        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}