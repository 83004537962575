import { Card, CardHeader, Checkbox, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import JumboIconButton from '@jumbo/components/JumboIconButton';
import { useSelector } from 'react-redux';
import { Add } from '@mui/icons-material';
import CardContentData from './cardContent';


const label = { inputProps: { 'aria-label': 'controlled' } };


const ChipsAddRemove = ({ selectedTakes, boxId, boxName, setBoxTakeDetails, sx, wrapperSx, title, subheader, noWrapper, children }) => {
    const [chipData, setChipData] = useState([]);
    const [dummyChipData, setDummyChipData] = useState([]);
    const [checkBox, setCheckBox] = useState(false);
    const { detailedGetMySubTask } = useSelector(state => state.typereducer)
    const [finalDataPayloadChip1, setFinalDataPayloadChip1] = useState();
    const [addCard, setAddCard] = useState(false);

    const handleCheckBox = (event) => {
        var { name, checked } = event.target
        name = checked;
        setCheckBox(name);
    }

    const addNewCard = () => {

        setAddCard(true);
    }

    useEffect(() => {
        const getDocumentsForBox = (allTakes, boxId) => {
            return allTakes?.filter(itm => itm.box_id === boxId)?.map(item => {
                return { key: item._id, label: item.take_name, flag: item.check_box_flag || false, studio: item.studio || '', date: item.date || '', camera_name: item.camera_name || '' }
            })
        }
        setChipData(getDocumentsForBox(detailedGetMySubTask, boxId))
        setDummyChipData(getDocumentsForBox(detailedGetMySubTask, boxId))

    }, [detailedGetMySubTask])




    useEffect(() => {
        if (chipData.length > 0) {
            setCheckBox(chipData?.[0]?.flag)
        }
        else {
            setCheckBox(false)

        }
    }, [dummyChipData])


    useEffect(() => {
        const temp = Object.assign([], finalDataPayloadChip1)

        if (finalDataPayloadChip1 !== undefined) {
            setChipData(old => temp)
        }

        // console.log('finalDataPayloadchip1', finalDataPayloadChip1);
        setBoxTakeDetails(boxId, title, finalDataPayloadChip1 || [], checkBox || false)

    }, [finalDataPayloadChip1])

    useEffect(() => {
        setBoxTakeDetails(boxId, title, finalDataPayloadChip1 || [], checkBox || false)
    }, [checkBox])

    // console.log("chipdata-->-->", chipData);

    return (
        <React.Fragment>
            <Card
                sx={sx}
            >
                <CardHeader
                    title={
                        title && (
                            <Typography variant={"h4"} mb={0}>{title}</Typography>
                        )
                    }
                    // subheader={
                    //     subheader && (
                    //         <Typography sx={{ mt: 1, color: "text.secondary" }}>{subheader}</Typography>
                    //     )
                    // }
                    action={
                        <>
                            <IconButton onClick={addNewCard}
                                sx={{
                                    margin: '-10px -6px -10px 0'
                                }}
                            >
                                <Add />
                            </IconButton>
                            <JumboIconButton
                            >
                                <Checkbox
                                    checked={checkBox}
                                    {...label} onChange={handleCheckBox} />
                            </JumboIconButton>
                        </>
                    }
                />
                <CardContentData
                    data={chipData}
                    stylesx={wrapperSx}
                    check={checkBox}
                    boxID={boxId}
                    titleZ={title}
                    checkBOX={checkBox}
                    dataPayload={(a, b, data1, checkfinal) => {
                        const temp = Object.assign([], data1)
                        setFinalDataPayloadChip1(temp);
                        setCheckBox(checkfinal)

                    }}
                    cardBoolean={addCard}
                    cardBooleanSet={(bool) => {
                        setAddCard(bool)
                    }}

                />
            </Card>

        </React.Fragment>


    );
};


export default ChipsAddRemove;









