import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { Avatar, Card, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const AutomatedScriptItem = ({ scriptDetail }) => {
    return (

        <Card sx={{ mb: 1 }}>
            <Stack direction={"row"} alignItems={"center"} sx={{ p: theme => theme.spacing(2, 1) }}>
                <Item
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item>
                            <Typography variant={"h5"} mb={.5}>{scriptDetail?.asin}</Typography>
                            <Typography variant={"h6"} mb={.5}>{scriptDetail?.content}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Content</Typography>
                        </Item>
                    </Stack>
                </Item>
            </Stack>
        </Card>

    );
};

export default AutomatedScriptItem;
