import * as yup from "yup";
// import {useMutation} from "react-query";
// import {contactService} from "../../../../../services/contact-services";
import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import Div from "@jumbo/shared/Div";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import subTaskService from 'app/services/subtask_service';
import { Form, Formik } from "formik";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const PaymentStatusForm = ({ expanseId, paymentStatus, paymentDate, status }) => {
    // //console.log(expanseId)
    const Swal = useSwalWrapper();
    const data = localStorage.getItem("user");
    const userInfo = JSON.parse(data)
    const { hideDialog } = useJumboDialog();
    const sweetAlerts = variant => {
        Swal.fire({
            icon: variant,
            title: variant,
            text: variant === "success" ? 'Payment Status Updated Successfully!' : "Something went wrong",
        }).then(result => {
            window.location.reload();
        });
    };

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1; // Month is zero-based, so add 1
        if (month < 10) {
          month = `0${month}`; // Ensure two-digit month
        }
        let day = currentDate.getDate();
        if (day < 10) {
          day = `0${day}`; // Ensure two-digit day
        }
        return `${year}-${month}-${day}`;
      }
    return (
        <Formik
            enableReinitialize
            initialValues={{
                paymentStatus: false,
                paymentDate: '',
                status:''
            }}
            validationSchema={yup.object().shape({
                // card_title: Yup.string().max(255).required('Highest Priorities is required'),
            })}
            onSubmit={(values) => {
                // //console.log("onSubmit:", values);
                let payload = {
                    payment_status: values?.paymentStatus,
                    payment_date: values?.paymentDate,
                    status: status
                }
                // //console.log("onSubmit payload:", payload);
                subTaskService.updatePaymentStatus(payload, expanseId).then((data) => {
                    hideDialog()
                    sweetAlerts('success')
                }).catch((err) => {
                    hideDialog()
                    if (err?.response?.status == 401) {
                        sweetAlerts('error')
                    }
                })
            }}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                // isSubmitting,
                touched,
                values
            }) => {
                // //console.log('values------', values);
                return (
                    <Form noValidate autoComplete="off">
                        <Div
                            sx={{
                                '& .MuiTextField-root': {
                                    mb: 3
                                },
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked={values?.paymentStatus} // Set the defaultChecked attribute here
                                        onChange={handleChange}

                                    />
                                }
                                name="paymentStatus"
                                value={values?.paymentStatus}
                                label="Paid"
                            />


                            <FormControl sx={{ width: "-webkit-fill-available" }}>
                                <TextField
                                    id="paymentDate"
                                    label="Payment Date"
                                    type="date"
                                    // sx={{ width: 220 }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        max: getCurrentDate(), // Use a function to get the current date
                                      }}
                                    onChange={handleChange}
                                    value={values?.paymentDate}
                                />
                            </FormControl>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{ mb: 3 }}
                            // loading={isSubmitting || saveMutation.isLoading}
                            >Save</LoadingButton>
                        </Div>
                    </Form>
                );
            }}
        </Formik>
    );
};
PaymentStatusForm.defaultProps = {
    onSave: () => {
    }
};
export default PaymentStatusForm;