import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { Avatar, Card, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const useStyles = makeStyles({
    customAvatar: {
        '& .MuiAvatar-img': {
            /* Your custom CSS styles for the img tag go here */
            /* For example: */
            position: 'absolute',
            top: '10px',
            left: '30%',
            transform: 'translateX(-50%)',
            width: '100%',
            height: 'auto'
        },
    },
});

const AutomatedGraphicItem = ({ graphicLink, view }) => {
    const classes = useStyles();
    return (

        <Card sx={{ mb: 1 }}>
            <Stack direction={"row"} alignItems={"center"} sx={{ p: theme => theme.spacing(2, 1) }}>
                <Item
                    sx={{
                        flex: { xs: 1, md: '0 1 30%', lg: '0 1 35%' }
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item>
                            <Badge overlap="circular" variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                <Avatar
                                    sx={{
                                        width: 100,
                                        height: 75,
                                    }}
                                    className={classes.customAvatar}
                                    alt={'Path Not Pound'}
                                    src={graphicLink?.productLink}
                                />
                            </Badge>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{graphicLink?.productName}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Name</Typography>
                        </Item>
                        <Item>
                            <a href={graphicLink?.productLink} target="_blank"><Typography variant={"h6"} mb={.5}>{graphicLink?.productLink}</Typography></a>
                        </Item>
                    </Stack>
                </Item>
            </Stack>
        </Card>

    );
};

export default AutomatedGraphicItem;
