import Page from "@jumbo/shared/Page";
import DetailedSubTasks from "app/pages/DetailedSubTask";
import UsersList from "app/pages/UsersList";
import TableView from "app/pages/TableViewPage/tableviewPage";
import HrOfficeExpense from "app/pages/HrOfficeExpanses/HrOfficeExpanses";
import HrEmployeeLeave from "app/pages/HrEmployeeLeave/HrEmployeeLeave";
import AsinDataTable from "app/pages/home/AsinData/AsinDataTable";
import AsinDetailData from "app/pages/home/AsinData/AsinDetailData";
import AutomatedScripts from "app/pages/home/AutomatedScripts/AutomatedScripts";
import AutomatedGraphics from "app/pages/home/AutomatedGraphics/AutomatedGraphics";
import AutomatedGraphicsDetailData from "app/pages/home/AutomatedGraphics/AutomatedGraphicsDetailData";
import AutomatedScriptDetailData from "app/pages/home/AutomatedScripts/AutomatedScriptDetailData";
import EmployeeLeaveApprove from "app/pages/EmployeeLeaveApprove/EmployeeLeaveApprove";
import EmployeeExpenseApprove from "app/pages/EmployeeExpenseApprove/EmployeeExpenseApprove";
import AutomatedGraphicsTemplates from "app/pages/home/AutomatedGraphics/AutomatedGraphicsTemplates";
import AutomatedScriptsTemplates from "app/pages/home/AutomatedScripts/AutomatedScriptsTemplates";
import AutomatedVO from "app/pages/AutomatedVO/AutomatedVO";
import AutomatedVoDetail from "app/pages/AutomatedVO/AutomatedVoDetail";
import ApprovedExpenses from "app/pages/ApprovedExpense/ApprovedExpense";
import Vendors from "app/pages/Vendors/Vendors";
import OperationalExpenses from "app/pages/OperationalExpense/OperationalExpenses";
import AllExpenses from "app/pages/AllExpenses/AllExpenses";
import AllOpExpenses from "app/pages/AllOpExpenses/AllOpExpenses";
import OpExpenseApprove from "app/pages/OpExpenseApprove/OpExpenseApprove";
import InfluencerAppMapping from "app/pages/InfluencerAppMapping/InfluencerAppMapping";
import Invoices from "app/pages/Invoices/Invoices";
import ApproveExpensesFinances from "app/pages/ApproveExpensesFinance/ApproveExpensesFinance";
const listViewRoutes = [
    {
        path: "/footages",
        element: <Page component={UsersList}/>,

    },
    {
        path : "/footages/detailedView",
        element : <Page component={DetailedSubTasks}/>,
    },
    {
        path : "/footages/detailedView/tableView",
        element : <Page component={TableView}/>
    },
    {
        path : "/asin-data",
        element : <Page component={AsinDataTable}/>
    },
    {
        path : "/asin-data/details",
        element : <Page component={AsinDetailData}/>
    },
    {
        path : "/automated-scripts",
        element : <Page component={AutomatedScripts}/>
    },
    {
        path : "/automated-scripts-templates",
        element : <Page component={AutomatedScriptsTemplates}/>
    },
    {
        path : "/automated-scripts/details",
        element : <Page component={AutomatedScriptDetailData}/>
    },
    {
        path : "/automated-graphics",
        element : <Page component={AutomatedGraphics}/>
    },
    {
        path : "/automated-graphics-templates",
        element : <Page component={AutomatedGraphicsTemplates}/>
    },
    {
        path : "/automated-graphics/details",
        element : <Page component={AutomatedGraphicsDetailData}/>
    },
    {
        path : "/automated-vo",
        element : <Page component={AutomatedVO}/>
    },
    {
        path : "/automated-vo/details",
        element : <Page component={AutomatedVoDetail}/>
    },
    {
        path : "/hr-employee-leave",
        element : <Page component={HrEmployeeLeave}/>
    },
    {
        path : "/approve-leave",
        element : <Page component={EmployeeLeaveApprove}/>
    },
    {
        path : "/hr-office-expenses",
        element : <Page component={HrOfficeExpense}/>
    },
    {
        path : "/approve-expense",
        element : <Page component={EmployeeExpenseApprove}/>
    },
    {
        path: "/approve-op-expenses",
        element : <Page component={OpExpenseApprove}/>
    },
    {
        path : "/all-expense",
        element : <Page component={AllExpenses}/>
    },
    {
        path : "/approved-expenses",
        element : <Page component={ApprovedExpenses}/>
    },
    {
        path : "/vendor-metadata",
        element : <Page component={Vendors}/>
    },
    {
        path : "/op-expenses",
        element : <Page component={OperationalExpenses}/>
    },
    {
        path : "/all-op-expenses",
        element : <Page component={AllOpExpenses}/>
    },
    {
        path : "/influencer-app-mapping",
        element : <Page component={InfluencerAppMapping}/>
    },
    {
        path : "/invoices",
        element : <Page component={Invoices}/>
    },
    {

        path : "/approve-expenses-finance",
        element : <Page component={ApproveExpensesFinances}/>
    }
];


export default listViewRoutes;
