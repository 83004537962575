import { Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react';

const columns = [
    {
        field: 'id',
        headerName: 'ID',
        sortable: false,
        hideable: false,
        filterable: false,
        flex: 100,
        maxWidth: 100,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'asin',
        headerName: 'Asin',
        sortable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'url',
        headerName: 'URL',
        sortable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'productName',
        headerName: 'Product Name',
        sortable: false,
        filterable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'mrp',
        headerName: 'MRP',
        sortable: false,
        filterable: false,
        flex: 160,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'price',
        headerName: 'Price',
        sortable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'breadCrumbs',
        headerName: 'Breadcrumbs',
        sortable: false,
        flex: 250,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'stars',
        headerName: 'Stars',
        sortable: false,
        flex: 190,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'brand',
        headerName: 'Brand',
        sortable: false,
        filterable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'description',
        headerName: 'Description',
        sortable: false,
        filterable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
    {
        field: 'delivery',
        headerName: 'Delivery Date',
        sortable: false,
        filterable: false,
        flex: 220,
        align: 'center',
        headerAlign: 'center'
    },
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function AsinNumberTableView({ asinNumberTableData }) {

    const [page, setPage] = React.useState(1);
    const [totalCount, setTotalCount] = React.useState(asinNumberTableData?.length);
    const limit = 100;
    const [pageId, setPageId] = React.useState(1);

    var _id = pageId;

    const rows = asinNumberTableData?.map((item) => {

        return (
            {
                id: _id++,
                asin: item?.asin,
                url: `https://ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=IN&ASIN=${item?.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL80`,
                productName: item?.title,
                mrp: item?.listPrice?.currency + item?.listPrice?.value,
                price: item?.price?.currency + item?.price?.value,
                breadCrumbs: item?.breadCrumbs,
                stars: item?.stars,
                delivery: item?.delivery,
                brand: item?.brand,
                description: item?.description,
            }
        )
    })

    const handleValue = (event, value) => {
        setPage(value)
        setPageId(((value - 1) * limit) + 1);
    }

    return (
        <>
            <div style={{ height: '100%', width: '100%' }}>

                <DataGrid sx={{ backgroundColor: '#F6F4FA' }}
                    rows={[...rows]}
                    columns={[...columns]}
                    pageSize={30}
                    autoPageSize={true}
                    autoHeight={true}
                    density='standard'
                    // filterMode='server'
                    // onFilterModelChange={onFilterChange}
                    hideFooter={true}
                />
                <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
            </div>

        </>
    );
}