import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from '@mui/icons-material/Edit';
import { Button, Pagination } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ExpenseDocumentBox from '../HrOfficeExpanses/ExpenseDocumentBox';
import ExpenseApprovalFrom from '../EmployeeExpenseApprove/ExpenseApprovalFrom';
import { expenseApprovalData, hrOfficeApproveExpenseCleanUp } from 'app/redux/actions/subTaskAction';
import { docBaseUrl } from 'app/services/config';
export default function ApprovedExpenses() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();

  const { employeeOfficeExpensesData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const limit = 30;
  const [pageId, setPageId] = React.useState(1);

  var _id = pageId;
  const rows = employeeOfficeExpensesData.map((item) => {
    return (
      {
        id: _id++,
        employeeName: item?.employee_id.name,
        expenseId: item?.id,
        expenseAmount: item?.expense_amount,
        expenseDate: moment(item?.expense_date).format('DD MMM YYYY'),
        expenseDescription: item?.expense_description || '--',
        expenseDoc: item?.bill_reciept_documents || '--',
        status: item?.status || 'Requested',
        actionRemark: item?.action_remark || '--',
      }
    )
  })
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // //console.log("params----", params.row);
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => showApproveExpenseDialog(params.row.expenseId)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
            </NavLink>
          </div>
        );
      },
    },
    {
      field: 'employeeName',
      headerName: 'Empoyee Name',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'actionRemark',
      headerName: 'Remark',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'expenseAmount',
      headerName: 'Expense Amount',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'expenseDate',
      headerName: 'Expense Date',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'expenseDescription',
      headerName: 'Description',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'expenseDoc',
      headerName: 'Documents',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <ArticleIcon onClick={() => showExpenseDialog(params.value)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
          </div>
        );
      },
    },
  ];
  const showExpenseDialog = (expenseDoc) => {
    showDialog({
      title: "Expense Documents",
      content: <ExpenseDocumentBox expenseDoc={expenseDoc} />
    });
  }
  const handleExpenseUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showApproveExpenseDialog = React.useCallback((expenseId) => {
    showDialog({
      title: "Expense",
      content: <ExpenseApprovalFrom expenseId={expenseId} onSave={handleExpenseUpdate} />
    });
  }, [handleExpenseUpdate, showDialog]);
  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }

  const handleExport = () => {
    var filterQuery;
    if (queryOptions?.filterModel?.items?.[0]?.columnField !== undefined) {
      filterQuery = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`
    }
    else {
      filterQuery = ''
    }

    subTaskService.getExpensesApprovals(filterQuery).then((data) => {

      const mockData = data?.data?.data?.map((item, index) => {
        return ({
          "id": index + 1,
          "employeeName": item?.employee_id.name || '--',
          "status": item?.status || 'Requested',
          "actionRemark": item?.action_remark || "--",
          "expenseAmount": item?.expense_amount || '--',
          "expenseDate": moment(item?.expense_date).format('DD MMM YYYY') || '--',
          "expenseDescription": item?.expense_description || '--',
          "expenseDoc": item?.bill_reciept_documents.map((doc) => {
            return "https://assets.thezikhara.in/" + doc?.base_url + "/" + doc?.key
            // return docBaseUrl + doc?.base_url + "/" + doc?.key
          }) || '--',
        })
      })

      function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
          var line = '';
          for (var index in array[i]) {
            if (line !== '') line += ','

            line += array[i][index];
          }

          str += line + '\r\n';
        }

        return str;
      }

      function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
          items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

      var headers = {

        id: "ID",
        employeeName: "Employee Name",
        status: "Status",
        actionRemark: "Remark",
        expenseAmount: "Expense Amount",
        expenseDate: "Expense Date",
        expenseDescription: "Expense Description",
        expenseDoc: "Documents"
      };

      var fileTitle = 'Employee Expense Export'; // or 'my-unique-title'



      exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download


    }).catch((err) => {
      if (err?.response?.status === 401) {
        setAuthToken(null);
      }
    })
  }

  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value !== undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    if (queryOptions?.filterModel?.items?.[0]?.columnField !== undefined) {

      filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    } else {
      filterString = ''
    }
    subTaskService.getExpensesApprovals(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(expenseApprovalData(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status === 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(hrOfficeApproveExpenseCleanUp())
    }
    // subTaskService.subTaskGetMyFootageUploadData(filterString).then((data)=>{
    //   dispatch(uploadBatchExport(data?.data))

    // }).catch((err) => {
    //   if (err?.response?.status == 401) {
    //     setAuthToken(null);
    //   }
    // })

  }, [offset, queryOptions])
  const handleLeaveAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialog = React.useCallback(() => {
    showDialog({
      title: "Update Expense",
      //   content: <OfficeExpenseForm onSave={handleLeaveAdd} />
    });
  }, [handleLeaveAdd, showDialog]);

  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
        <Button onClick={handleExport} sx={{ float: 'right' }} variant="contained">
          Export Expanse
        </Button>

      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGrid sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}
        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}