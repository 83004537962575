import { FOOTAGES_UPLOAD_GET_DATA, SUBTASK_CAMERA_DETAILS, SUBTASK_DETAIL_CLEAN_UP, SUBTASK_DETAIL_GET_DATA, SUBTASK_DETAIL_GET_DATA_SIDE_INFO, SUBTASK_DETAIL_GET_MY_SUBTASK, SUBTASK_DETAIL_UPDATE_DATA, SUBTASK_GET_DATA, SUBTASK_STUDIO_DETAILS, TABLE_VIEW_DATA_CLEAN_UP, USER_LIST_DATA_CLEAN_UP, GET_HR_EMPLOYEE_LEAVE_DATA, GET_EMPLOYEE_LEAVE_APPROVAL_DATA, GET_HR_OFFICE_EXPENSES_DATA, GET_EMPLOYEE_OFFICE_EXPENSES_DATA, ASIN_NUMBER_GET_DATA, SCRIPT_MODELS_DATA, AUTOMATED_VO_DATA, EMPLOYEE_LEAVE_DATA_CLEAN_UP, EMPLOYEE_APPROVE_LEAVE_DATA_CLEAN_UP, HR_OFFICE_EXPENSE_DATA_CLEAN_UP, HR_OFFICE_APPROVE_EXPENSE_DATA_CLEAN_UP, GET_VENDOR_DATA,VENDOR_DATA_CLEAN_UP , GET_VENDOR_NAME, VENDOR_NAME_CLEAN_UP, GET_OPERATIONAL_EXPENSES_DATA, OPERATIONAL_EXPENSES_CLEAN_UP, GET_ALL_OFFICE_EXPENSESS_DATA, GET_ALL_EXPENSESS_DATA_CLEAN_UP,GET_ALL_OP_EXPENSESS_DATA_CLEAN_UP,GET_ALL_OP_EXPENSESS_DATA,GET_OP_EXPENSES_DATA,OP_APPROVE_EXPENSE_DATA_CLEAN_UP,GET_INFLUENCER_APP_MAPPING_DATA,GET_PROJECT_MANAGER_NAME,PROJECT_MANAGER_NAME_CLEAN_UP,INFLUENCER_APP_MAPPING_DATA_CLEAN_UP} from "app/utils/constants/types";

export const subtaskGetData = (subtaskData) => {
    return { type: SUBTASK_GET_DATA, payload: subtaskData }
};

export const subtaskDetailGetData = (subTaskDetailData) => {
    return { type: SUBTASK_DETAIL_GET_DATA, payload: subTaskDetailData }
};

export const subtaskDetailGetDataSideInfo = (subtaskDetailSideInfo) => {
    return { type: SUBTASK_DETAIL_GET_DATA_SIDE_INFO, payload: subtaskDetailSideInfo }
}

export const subtaskDetailsGetMySubstask = (getmysubtaskdetails) => {
    return { type: SUBTASK_DETAIL_GET_MY_SUBTASK, payload: getmysubtaskdetails }
}

export const subtaskDetailsPostMySubtask = (getmysubtaskpostdetails) => {
    return { type: SUBTASK_DETAIL_UPDATE_DATA, payload: getmysubtaskpostdetails }
}

export const subtaskDetailsCleanUp = () => {
    return { type: SUBTASK_DETAIL_CLEAN_UP }
}

export const footageUploadGetdata = (getmyfootageupload) => {
    return { type: FOOTAGES_UPLOAD_GET_DATA, payload: getmyfootageupload }
}

export const tableViewCleanUp = () => {
    return { type: TABLE_VIEW_DATA_CLEAN_UP }
}

export const userListCleanUp = () => {
    return { type: USER_LIST_DATA_CLEAN_UP }
}

export const subtaskStudioDetails = (getmystudio) => {
    return { type: SUBTASK_STUDIO_DETAILS, payload: getmystudio }
}
export const subtaskCameraDetails = (getmycamera) => {
    return { type: SUBTASK_CAMERA_DETAILS, payload: getmycamera }
}
export const hrEmployeeLeaveCleanUp = () => {
    return { type: EMPLOYEE_LEAVE_DATA_CLEAN_UP }
}
export const hrEmployeeApproveLeaveCleanUp = () => {
    return { type: EMPLOYEE_APPROVE_LEAVE_DATA_CLEAN_UP }
}
export const getHrEmployeeLeaveData = (gethremployeeleavedata) => {
    return { type: GET_HR_EMPLOYEE_LEAVE_DATA, payload: gethremployeeleavedata }
}
export const employeeLeaveApprovalDetail = (employeeleaveapprovaldata) => {
    return { type: GET_EMPLOYEE_LEAVE_APPROVAL_DATA, payload: employeeleaveapprovaldata }
}
export const hrOfficeExpenseCleanUp = () => {
    return { type: HR_OFFICE_EXPENSE_DATA_CLEAN_UP }
}
export const hrOfficeApproveExpenseCleanUp = () => {
    return { type: HR_OFFICE_APPROVE_EXPENSE_DATA_CLEAN_UP }
}
export const OpApproveExpenseCleanUp = () => {
    return { type: OP_APPROVE_EXPENSE_DATA_CLEAN_UP }
}
export const getAllExpensessCleanUp = () => {
    return { type: GET_ALL_EXPENSESS_DATA_CLEAN_UP }
}
export const getAllOpExpensessCleanUp = () => {
    return { type: GET_ALL_OP_EXPENSESS_DATA_CLEAN_UP }
}
export const hrOffliceExpenses = (gethrofficeexpensedata) => {
    return { type: GET_HR_OFFICE_EXPENSES_DATA, payload: gethrofficeexpensedata }
}
export const expenseApprovalData = (employeexpensedata) => {
    return { type: GET_EMPLOYEE_OFFICE_EXPENSES_DATA, payload: employeexpensedata }
}
export const opExpenseApprovalData = (opexpensedata) => {
    return { type: GET_OP_EXPENSES_DATA, payload: opexpensedata }
}
export const getAllExpensesData = (employeexpensedata) => {
    return { type: GET_ALL_OFFICE_EXPENSESS_DATA, payload: employeexpensedata }
}
export const getAllOpExpensesData = (employeexpensedata) => {
    return { type: GET_ALL_OP_EXPENSESS_DATA, payload: employeexpensedata }
}
export const asinNumberGetdata = (asinNumberData) => {
    return { type: ASIN_NUMBER_GET_DATA, payload: asinNumberData }
};
export const allScriptModelData = (scriptModels) => {
    return { type: SCRIPT_MODELS_DATA, payload: scriptModels }
};
export const allAutomatedVoData = (automatedVo) => {
    return { type: AUTOMATED_VO_DATA, payload: automatedVo }
};

export const getVendorData = (vendorData) => {
    //console.log("vendorData----",vendorData);
    return { type: GET_VENDOR_DATA, payload: vendorData}
}
export const VendorDataCleanUp = () => {
    return { type: VENDOR_DATA_CLEAN_UP }
}
export const getVendorName = (vendorNameData) => {
    //console.log("vendorName----",vendorNameData);
    return { type: GET_VENDOR_NAME, payload: vendorNameData}
}

export const getProjectManagerName = (projectManagerNameData) => {
    //console.log("project manager Name----",projectManagerNameData);
    return { type: GET_PROJECT_MANAGER_NAME, payload: projectManagerNameData}
}
export const ProjectManagerNameCleanUp = () => {
    return { type: PROJECT_MANAGER_NAME_CLEAN_UP }
}

export const VendorNameCleanUp = () => {
    return { type: VENDOR_NAME_CLEAN_UP }
}

export const operationalExpenses = (getoperationalexpensedata) => {
    return { type: GET_OPERATIONAL_EXPENSES_DATA, payload: getoperationalexpensedata }
}
export const OperationalExpensesCleanUp = () => {
    return { type: OPERATIONAL_EXPENSES_CLEAN_UP }
}

export const getInfluencerAppMappingData = (influencerAppMappingData) => {
    //console.log("influencerDAta----",influencerAppMappingData);
    return { type: GET_INFLUENCER_APP_MAPPING_DATA, payload: influencerAppMappingData}
}
export const InfluencerAppMappingDataCleanUp = () => {
    return { type: INFLUENCER_APP_MAPPING_DATA_CLEAN_UP }
}