import {axios} from "./config";

const authServices = {};
authServices.getCurrentUser = async () => {
    const data = localStorage.getItem("user");
    return JSON.parse(data);
};
authServices.login = async (payload) => {
    const { data } = await axios.post("login",payload);
    return data;
};



export default authServices;