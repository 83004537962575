import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import Div from "@jumbo/shared/Div";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton } from "@mui/material";
import Zoom from "@mui/material/Zoom";
import React, { Suspense, useEffect } from 'react';
import Logo from "../../../../shared/Logo";
import SidebarSkeleton from "./SidebarSkeleton";
import menus from "./menus";
// import React, { useEffect } from "react";
import ListIcon from '@mui/icons-material/List';
import UploadIcon from '@mui/icons-material/Upload';

const Sidebar = () => {

    const fixedMenu = [
        {
            label: 'Footage Automation',
            type: "section",
            children: [
                // {
    
                //     uri: "/list-views/projects",
                //     label: 'sidebar.menuItem.projects',
                //     type: "nav-item",
                //     icon: <ViewListOutlinedIcon sx={{fontSize: 20}}/>
                // },
                
                {
                    uri: "/footages",
                    label: 'sidebar.menuItem.footages',
                    type: "nav-item",
                    icon: <ListIcon sx={{ fontSize: 20 }} />
                },
                {
                    uri: "/footages/detailedView/tableView",
                    label: 'Upload Batches',
                    type: "nav-item",
                    icon: <UploadIcon sx={{ fontSize: 20 }} />
                },
            ]
        }
    ];
    const [currentRoleCode, setCurrentRoleCode] = React.useState('');
    useEffect(()=>{
        setCurrentRoleCode(localStorage.getItem('roleCode'));
        return ()=>{
            setCurrentRoleCode('')
        }
    },[])

    useEffect(()=>{
        setCurrentRoleCode(localStorage.getItem('roleCode'));
        return ()=>{
            setCurrentRoleCode('')
        }
    },[localStorage.getItem('roleCode')])


    const currentMenus = currentRoleCode != '' ? menus(currentRoleCode) : fixedMenu
    // //console.log('debug',currentMenus);
    return (
        <React.Fragment>
            <SidebarHeader/>
            <JumboScrollbar
                autoHide
                autoHideDuration={200}
                autoHideTimeout={500}
            >
                <Suspense
                    fallback={
                        <Div
                            sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                px: 3
                            }}
                        >
                            <SidebarSkeleton/>
                        </Div>
                    }
                >
                    {/* <Menu /> */}
                    <JumboVerticalNavbar translate items={currentMenus}/>
                </Suspense>
            </JumboScrollbar>
        </React.Fragment>
    );
};

const SidebarHeader = () => {
    const {sidebarOptions, setSidebarOptions} = useJumboLayoutSidebar();
    const {sidebarTheme} = useJumboSidebarTheme();

    const isMiniAndClosed = React.useMemo(() => {
        return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
    }, [sidebarOptions.view, sidebarOptions.open]);


    return (
        <React.Fragment>
            {
                sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER &&
                <DrawerHeader>
                    <Logo mini={isMiniAndClosed} mode={sidebarTheme.type}/>
                    {
                        sidebarOptions?.view !== SIDEBAR_VIEWS.MINI &&
                        <Zoom in={sidebarOptions?.open}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ml: 0, mr: -1.5}}
                                onClick={() => setSidebarOptions({open: false})}
                            >
                                <MenuOpenIcon/>
                            </IconButton>
                        </Zoom>
                    }
                </DrawerHeader>
            }
        </React.Fragment>
    )
};

export default Sidebar;
