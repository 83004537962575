import JumboDdPopover from "@jumbo/components/JumboDdPopover";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import useJumboTheme from "@jumbo/hooks/useJumboTheme";
import Div from "@jumbo/shared/Div";
import LogoutIcon from '@mui/icons-material/Logout';
import { ListItemIcon, ListItemText, ThemeProvider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import { useNavigate } from "react-router-dom";

const AuthUserDropdown = () => {
    const navigate = useNavigate();
    const { theme } = useJumboTheme();
    const { setAuthToken } = useJumboAuth();
    const data = localStorage.getItem("user");
    const userInfo = JSON.parse(data);

    const onLogout = () => {
        setAuthToken(null);
        navigate("/user/login");
    };

    // const typereducer = useSelector

    return (
        <ThemeProvider theme={theme}>
            <Div>{userInfo?.name}</Div>
            <JumboDdPopover
                triggerButton={
                    <Avatar
                        src='/images/logos/project-logo-10.png'
                        sizes={"small"}
                        sx={{ boxShadow: 25, cursor: 'pointer' }}
                    />
                }
            >
                {/* <Div sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    p: theme => theme.spacing(2.5),
                }}>
                    <Avatar src={authUser.profile_pic} alt={authUser.name} sx={{width: 60, height: 60, mb: 2}}/>
                    <Typography variant={"h5"}>{authUser.name}</Typography>
                    <Typography variant={"body1"} color="text.secondary">{authUser.handle}</Typography>
                </Div> */}
                <Divider />
                <nav>
                    <List disablePadding sx={{ pb: 1 }}>
                        {/* <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <PersonOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" sx={{my: 0}}/>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <EditOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Edit Profile" sx={{my: 0}}/>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon sx={{minWidth: 36}}>
                                <RepeatOutlinedIcon/>
                            </ListItemIcon>
                            <ListItemText onClick={() => navigate("/samples/content-layout")} primary="Switch User"
                                          sx={{my: 0}}/>
                        </ListItemButton> */}
                        <ListItemButton onClick={onLogout}>
                            <ListItemIcon sx={{ minWidth: 36 }}>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary="Logout" sx={{ my: 0 }} />
                        </ListItemButton>
                    </List>
                </nav>
            </JumboDdPopover>
        </ThemeProvider>
    );
};

export default AuthUserDropdown;
