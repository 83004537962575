import Div from "@jumbo/shared/Div";
import Typography from '@mui/material/Typography';
import { docBaseUrl } from 'app/services/config';
const ExpenseDocumentBox = ({ expenseDoc }) => {

    return (
        <Div>
            {
                expenseDoc?.map((item, index) => {
                    return (
                        <a href={docBaseUrl + item?.base_url + "/" + item?.key} target="_blank"><Typography variant={"h6"} mb={.5}>{index + 1}. {item?.key}</Typography></a>
                        // <a href={docBaseUrl + item?.base_url + "/" + item?.key} target="_blank"><Typography variant={"h6"} mb={.5}>{index + 1}. {item?.key}</Typography></a>
                    )
                })
            }
        </Div>
    );
};
export default ExpenseDocumentBox;