import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { Card, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const AutomatedVotem = ({ VoData, view }) => {
    return (

        <Card sx={{ mb: 1 }}>
            <Stack direction={"row"} alignItems={"center"} sx={{ p: theme => theme.spacing(2, 1) }}>
                <Item
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.asinNumber}</Typography>
                            <Typography variant={"body1"} color="text.secondary">asin Number</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v1}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 1</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v2}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 2</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v3}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 3</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v4}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 4</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v5}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 5</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v6}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 6</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v7}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 7</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v8}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 8</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v9}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 9</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v10}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 10</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v11}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 11</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v12}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 12</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v13}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 13</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v14}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 14</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{VoData?.v15}</Typography>
                            <Typography variant={"body1"} color="text.secondary">VO 15</Typography>
                        </Item>
                    </Stack>
                </Item>
            </Stack>
        </Card>

    );
};

export default AutomatedVotem;
