import React from 'react';

const Home = () => {
    return (
        <div>
            <h2>Zikhara App</h2>
            <p>Footage Automations!!</p>
        </div>
    );
};

export default Home;