import styled from "@emotion/styled";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from 'react-router-dom';
import { getAssetPath } from "../../../utils/appHelpers";
import { ASSET_IMAGES } from "../../../utils/constants/paths";
import subTaskService from "app/services/subtask_service";
import React from "react";
import { allScriptModelData } from "app/redux/actions/subTaskAction";
import { useDispatch, useSelector } from "react-redux";

const Item = styled("div")(({ theme }) => ({
    ...theme.typography.body2,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
const templateDetails = [
    {
        scriptModelName: "First Script",
        modelKey: "simple_product_graphic",
        modelId: "64758327237d1dbd483e2699",
        sampleFile: "/images/sample_simple_product_graphic.csv",
    },
    {
        scriptModelName: "Second Script",
        modelKey: "vse_product_graphic",
        modelId: "64393b7f2fdbceea2acbfc20",
        sampleFile: "/images/sample_vse_product_graphic.csv",
    },
]
const AutomatedScriptsTemplates = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { scriptModels } = useSelector(state => state.typereducer);

    const onTemplateClick = (template) => {
        navigate(`/automated-scripts?&template=${template?.model_id}`, { state: template })
    }
    React.useEffect(() => {
        subTaskService.getAllScriptModel().then((data) => {
            dispatch(allScriptModelData(data?.results))
        }).catch((err) => {
            if (err?.response?.status == 401) {
                // setAuthToken(null);
            }
        })
    }, [])
    return (
        <Grid container spacing={3.75}>
            {
                scriptModels.map((template, index) => (
                    <Grid key={index} item xs={6} sm={6} lg={4}>
                        <JumboCardQuick noWrapper>
                            <JumboContent
                                action={<MoreHorizIcon />}
                                bgImage={getAssetPath(`${ASSET_IMAGES}/event2.jpg`, "420x380")}
                                backdrop
                                sx={{ height: 265 }}
                            >
                                <Stack alignItems={"center"} sx={{ p: theme => theme.spacing(0, 2), mt: -2 }}>
                                    <Typography variant={"h5"} color={"common.white"}>
                                        {template?.model_name}
                                    </Typography>
                                    <Typography variant={"h6"} color={"common.white"}>
                                        {template?.productDescription}
                                    </Typography>
                                </Stack>
                            </JumboContent>
                            <CardContent>
                                <Button variant="contained" onClick={() => onTemplateClick(template)}>
                                    Use Template
                                </Button>
                            </CardContent>
                        </JumboCardQuick>
                    </Grid>
                ))
            }
        </Grid>
        // </>
    );
};

export default AutomatedScriptsTemplates;
