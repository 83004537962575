import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import subTaskService from 'app/services/subtask_service';
import { useDispatch, useSelector } from 'react-redux';
import { footageUploadGetdata } from 'app/redux/actions/subTaskAction';
import moment from 'moment';
import { Button, Input, Modal, Pagination, Typography } from '@mui/material';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import Div from '@jumbo/shared/Div';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    sortable: false,
    hideable: false,
    filterable: false,
    flex: 100,
    maxWidth: 100,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'projectname',
    headerName: 'Project Name',
    sortable: false,
    flex: 220,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'taskname',
    headerName: 'Task Name',
    sortable: false,
    filterable: false,
    flex: 220,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'box',
    headerName: 'Box',
    sortable: false,
    filterable: false,
    flex: 160,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'takename',
    headerName: 'Take Name',
    sortable: false,
    flex: 220,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'uploadstatus',
    headerName: 'Upload Status',
    sortable: false,
    flex: 250,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    flex: 190,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'time',
    headerName: 'Time',
    sortable: false,
    filterable: false,
    flex: 220,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'error',
    headerName: 'Error',
    filterable: false,
    sortable: false,
    flex: 300,
    align: 'center',
    headerAlign: 'center'
  },
  {
    field: 'base_folder',
    headerName: 'Upload Path',
    filterable: false,
    sortable: false,
    flex: 300,
    align: 'center',
    headerAlign: 'center'
  }
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function TableView() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();

  const { footageUploadData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [passCode, setPassCode] = React.useState('');
  const [alert1, setAlert1] = React.useState(false);
  const [progress, setProgress] = React.useState(false);
  
  var _id = pageId;


  const rows = footageUploadData.map((item) => {

    return (
      {
        id: _id++,
        projectname: item?.project_data?.[0]?.name,
        taskname: item?.task_data?.[0]?.task,
        box: item?.box_title,
        takename: item?.take_name,
        uploadstatus: item?.present_status,
        action: item?.action,
        time: moment(item?.create_at).format('DD MMM YYYY'),
        error: item?.error_message || '--',
        base_folder: item?.base_folder || '--',
      }
    )
  })

  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }

  const handleExport = () => {
    var filterQuery;
    if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {
      filterQuery = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`
    }
    else {
      filterQuery = ''
    }

    subTaskService.subTaskGetMyFootageUploadData(filterQuery).then((data) => {
     

    const mockData = data?.data?.[0]?.paginatedResults.map((item, index) => {
    return ({
      "id": index + 1,
      "projectname": item?.project_data?.[0]?.name,
      "taskname": item?.task_data?.[0]?.task,
      "box": item?.box_title,
      "takename": item?.take_name,
      "uploadstatus": item?.present_status,
      "action": item?.action,
      "time": moment(item?.create_at).format('DD MMM YYYY'),
      "error": item?.error_message || '--',
      "basefolder": item?.base_folder || '--',
    })
  })

      function convertToCSV(objArray) {
        var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        var str = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ','
    
                line += array[i][index];
            }
    
            str += line + '\r\n';
        }
    
        return str;
    }

      function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
            items.unshift(headers);
        }
    
        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);
    
        var csv = convertToCSV(jsonObject);
    
        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';
    
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    var headers = {
      
      id: "ID",
      projectname: "Project Name",
      taskname:"Task Name",
      box: "Box",
      takename: "Take Name",
      uploadstatus: "Upload Status",
      action: "Action",
      time: "Time",
      error: "Error",
      basefolder: "Base Folder",
  };

  var fileTitle = 'Upload Batch Export'; // or 'my-unique-title'
  
  

  exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download


    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
  }

  const handleManualTrigger = () => {
    setOpen(true);
  }
  const savePassCode = () => {
    if(passCode == '7546'){
      setAlert1(false);
      setOpen(false);
      setPassCode('');
      setProgress(true);
      console.log('passCode matched');
      subTaskService.checkAuthForManualTrigger().then((data) => {
       console.log('message',data);
       setProgress(false)
      }).catch((err) => {
        if (err?.response?.status == 401) {
          setAuthToken(null);
        }
      })
    }else{
      setAlert1(true);
    }
  }


  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

      filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    } else {
      filterString = ''
    }
    subTaskService.subTaskGetMyFootageUploadData(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.[0]?.totalCount?.[0]?.count);
      dispatch(footageUploadGetdata(data?.data?.[0]?.paginatedResults))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })

    // subTaskService.subTaskGetMyFootageUploadData(filterString).then((data)=>{
    //   dispatch(uploadBatchExport(data?.data))

    // }).catch((err) => {
    //   if (err?.response?.status == 401) {
    //     setAuthToken(null);
    //   }
    // })

  }, [offset, queryOptions])

  console.log('passCode' ,passCode);

  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
        <Button sx={{float:'right' }} variant="contained" onClick={handleExport}>
          Export CSV
        </Button>
        <Button sx={{float:'right', marginRight : 1}}variant="contained" onClick={handleManualTrigger}>
          {progress ? 'Loading...' :'Manual Trigger'}
        </Button>

      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGrid sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}


        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
        <Modal
                    open={open}
                    onClose={()=>{setOpen(false);
                      setAlert1(false);
                      setPassCode('')
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Div sx={style}>
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Please Enter PassCode
                        </Typography>
                        { alert1 && <Typography sx={{mt: 3}} style={{color : 'red'}}>* Wrong Credentials</Typography>}
                        <Input id="modal-modal-description" style={{display : 'block'}} value={passCode} onChange={(e)=>{setPassCode(e.target.value)}}/>
                        <Button variant="contained" onClick={savePassCode} sx={{mt : 2}}>
                          Trigger
                        </Button>
                    </Div>
                </Modal>
      </div>
              
    </>
  );
}