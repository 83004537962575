import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import { Button, Pagination } from '@mui/material';
// import { DataGrid} from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { getVendorData, VendorDataCleanUp } from 'app/redux/actions/subTaskAction';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VendorsForm from './VendorsForm';
import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import useSwalWrapper from '@jumbo/vendors/sweetalert2/hooks';
// import Swal from 'sweetalert2';

export default function VendorsMetadata() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();
  const Swal = useSwalWrapper();
  const { vendorData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const limit = 10;
  const [pageId, setPageId] = React.useState(1);

  var _id = pageId;
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        // //console.log("params.row---", params.row);
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            <NavLink>
              <EditIcon onClick={() => showAddVendorDialog(params.row.vendorId)} style={{ width: '15px', height: "15px", marginLeft: "2px" }} color="action" />
            </NavLink>
            <NavLink>
              <DeleteIcon onClick={() => cancleLeaveRequest(params.row.vendorId)} style={{ width: '15px', height: "15px", marginLeft: "2px" }} color="action" />
            </NavLink>
          </div>
        );
      },
    },
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: false,
      // filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'vendorType',
      headerName: 'Vendor Type',
      sortable: false,
      flex: 250,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      sortable: false,
      // filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'gstNo',
      headerName: 'GST No.',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'panNo',
      headerName: 'PAN No.',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'tanNo',
      headerName: 'TAN No.',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'tdsAmount',
      headerName: 'TDS Amount ',
      sortable: false,
      filterable: false,
      flex: 250,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'billingDetails',
      headerName: 'Billing Details',
      sortable: false,
      filterable: false,
      flex: 250,
      align: 'center',
      headerAlign: 'center'
    },
    // {
    //   field: 'invoiceNumber',
    //   headerName: 'Invoice No.',
    //   sortable: false,
    //   filterable: false,
    //   flex: 220,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    
    // {
    //   field: 'actionRemark',
    //   headerName: 'Remark',
    //   sortable: false,
    //   filterable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveTime',
    //   headerName: 'Time',
    //   sortable: false,
    //   filterable: false,
    //   flex: 220,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveStartDate',
    //   headerName: 'Start Date',
    //   filterable: false,
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'leaveEndDate',
    //   headerName: 'End Date',
    //   filterable: false,
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'actionAt',
    //   headerName: 'Action At',
    //   filterable: false,
    //   sortable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // },
    // {
    //   field: 'actionBy',
    //   headerName: 'Action By',
    //   sortable: false,
    //   filterable: false,
    //   flex: 300,
    //   align: 'center',
    //   headerAlign: 'center'
    // }
  ];
  //console.log('vendor----------->',vendorData)
  const rows = vendorData.map((item) => {
    //console.log('item',item)
    return (
      {
        id: _id++,
        vendorId: item?.id,
        employeeName: item?.employee_id?.name,
        employeeId: item?.employee_id?.id,
        vendorName: item?.vendor_name,
        companyName: item?.company_name,
        gstNo: item?.gst_no,
        panNo: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(item?.pan_tan) ? item?.pan_tan : '',
        tanNo: /^[A-Z]{4}[0-9]{5}[A-Z]{1}$/.test(item?.pan_tan) ? item?.pan_tan : '',
        billingDetails: item?.billing_details,
        invoiceNumber: item?.invoice_number,
        vendorType: item?.vendor_type,
        tdsAmount: item?.tds_amount
      }
    )
  })

  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }
  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;
  const sweetAlerts = (variant, message,callback) => {
    Swal.fire({
      icon: variant,
      title: variant === "success" ? 'Success' : 'Warning',
      text: message,
      showCancelButton: variant !== "success", // Show cancel button for non-success alerts
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed && callback) {
        callback(); // Perform action if confirmed
      }
    });
  
  };

  React.useEffect(() => {
    // if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

    //   filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    // } else {
    //   filterString = ''
    // }
    if (queryOptions?.filterModel?.items) {
      filterString = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }else {
      filterString = ''
    }
    subTaskService.getVendorData(offset, limit, filterString).then((data) => {
      setTotalCount(data?.data?.count);
      dispatch(getVendorData(data?.data?.data))

    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
    return () => {
      dispatch(VendorDataCleanUp())
    }
  }, [offset, queryOptions])
  const handleVendorDataAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddVendorDialog = React.useCallback((vendorId) => {
    //console.log('id sent in form',vendorId)
    showDialog({
      title: "Add Vendor Data",
      content: <VendorsForm vendorId={vendorId} onSave={handleVendorDataAdd} />
    });
  }, [handleVendorDataAdd, showDialog]);
  const cancleLeaveRequest = (vendorId) => {
    sweetAlerts("warning", "Are you sure you want to delete this vendor?", () => {
      subTaskService.deleteVendorData(vendorId)
        .then((data) => {
          //console.log('deleted');
          hideDialog();
          sweetAlerts('success', 'Vendor Deleted Successfully!', () => {
            window.location.reload();
          });
        })
        .catch((err) => {
          hideDialog();
          if (err?.response?.status == 401) {
            sweetAlerts('error', 'Failed to delete vendor');
          }
        });
    });
  }
  const handleCellClick = (params, event) => {
    const columnId = params.field; // Get the value of the clicked cell
    //console.log('params.field',params.field)
    if (columnId != 'id' ) {
      const cellValue = params.value; // Get the value of the clicked cell

      Swal.fire({
          // title: 'Cell Content',
          html: `<div style="white-space: pre-wrap;">${cellValue}</div>`,
          // showCloseButton: true,
          showConfirmButton: false,
          customClass: {
              popup: 'my-custom-size', // Apply custom class to the dialog box
          },
          didOpen: () => {
              const swalPopup = Swal.getPopup();
              if (swalPopup) {
                  swalPopup.style.width = '400px'; // Modify the width as needed
              }
          },
      });
  }
};
  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
        <Button sx={{ float: 'right' }} variant="contained" onClick={() => showAddVendorDialog()}>
          Add Vendor
        </Button>
      </div>
      <div style={{ height: '100%', width: '100%' }}>

        <DataGridPro sx={{ backgroundColor: '#F6F4FA' }}
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          onFilterModelChange={onFilterChange}
          hideFooter={true}
          onCellClick={(params, event) => handleCellClick(params, event)}


        />
        <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} />
      </div>

    </>
  );
}