import React, { useEffect } from 'react';
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import { List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import Link from "@mui/material/Link";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import FolderOpen from "@mui/icons-material/FolderOpen";
import { useSelector } from 'react-redux';
import { ArrayMapProductCateogory, ArrayMapProductCompany } from './mapData';
import moment from 'moment';

const DetailedInfo = () => {
    const detailedInfo = useSelector(state => state.typereducer)
    const asinurl = "https://www.amazon.in/dp/" + detailedInfo?.detailedSideInfos?.dmfTask?.[0].asin;


    return (
        <JumboCardQuick title={"General Info"} noWrapper sx={{ mt: 14.6 }}>
            <List disablePadding sx={{ mb: 2 }}>
                {/* <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <EmailOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Product Name</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].product_name}</Typography>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <InsertLinkOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Asin</Typography>}
                        secondary={<Link variant="body1" href={asinurl} underline="none" target="_blank">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].asin}</Link>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Product Category</Typography>}
                        secondary={
                            detailedInfo?.detailedSideInfos?.dmfTask?.[0]?.productCategory?.map((user, index) => (
                                <ArrayMapProductCateogory user={user} key={index} />
                            ))
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Product Sub Category
                        </Typography>}
                        secondary={
                            detailedInfo?.detailedSideInfos?.dmfTask?.[0]?.productSubCategory?.map((user, index) => (
                                <ArrayMapProductCateogory user={user} key={index} />
                            ))
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Product Cost
                        </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].product_cost}</Typography>}
                    />
                </ListItem> */}
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Task</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].task}</Typography>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Task Code
                        </Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].video_code}</Typography>}
                    />
                </ListItem>
                {/* <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Languague</Typography>}
                        secondary={
                            detailedInfo?.detailedSideInfos?.dmfTask?.[0]?.languageMaster?.map((user, index) => (
                                <ArrayMapProductCateogory user={user} key={index} />
                            ))
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Location</Typography>}
                        secondary={
                            detailedInfo?.detailedSideInfos?.dmfTask?.[0]?.locationMaster?.map((user, index) => (
                                <ArrayMapProductCateogory user={user} key={index} />
                            ))
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Company</Typography>}
                        secondary={
                            detailedInfo?.detailedSideInfos?.dmfTask?.[0]?.projectMaster?.map((user, index) => (
                                <ArrayMapProductCompany user={user} key={index} />
                            ))
                        }
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Filming Date</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{moment(detailedInfo?.detailedInfos?.filming_date?.[0]).format('DD MMM YYYY')}</Typography>}
                    />
                </ListItem>
                <ListItem alignItems="flex-start" sx={{ p: theme => theme.spacing(.5, 3) }}>
                    <ListItemIcon sx={{ minWidth: 36, color: 'text.secondary' }}>
                        <FolderOpen />
                    </ListItemIcon>
                    <ListItemText
                        primary={<Typography variant="body1" color="text.secondary">Description</Typography>}
                        secondary={<Typography variant="body1" color="text.primary">{detailedInfo?.detailedSideInfos?.dmfTask?.[0].description}</Typography>}
                    />
                </ListItem> */}

            </List>
        </JumboCardQuick>
    );
};

export default DetailedInfo;
