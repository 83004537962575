import baseAxios from "axios";

const ENV = "PROD";

console.log("ENV",ENV);

const configUrls = {
  "DEV": {
    "MAIN_LAMBDA_URL": "http://192.168.0.62:9000/api/v1",
    "ZIKHARA_API_URL": "http://192.168.0.62:9000/api/v1",
    "SCRIPT_URL": "https://openai.thezikhara.com/",
    "AUTOMATED_VO_URL": "https://4qmyt8eh78.execute-api.ap-south-1.amazonaws.com/dev",
    "PIANOSA_APP_V2_MAPPING": "http://192.168.0.62:4000/api/v1",
    "DOCUMENT_BASE_URL":"https://d20vmeuwl3jwbk.cloudfront.net/",
    "MUI_LICENSE_KEY" : "287507078eb754f7145be1ba4e7eb9d1Tz04MzI5MCxFPTE3MzgzMTcwOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
  },
  "STAGING": {
    "MAIN_LAMBDA_URL": "https://7wkmy756b1.execute-api.ap-south-1.amazonaws.com",
    "ZIKHARA_API_URL": "https://staging-apis.thezikhara.com/api/v1",
    "SCRIPT_URL": "https://openai.thezikhara.com/",
    "AUTOMATED_VO_URL": "https://4qmyt8eh78.execute-api.ap-south-1.amazonaws.com/dev",
    "PIANOSA_APP_V2_MAPPING": "https://app-api.thezikhara.com/api/v1",
    "DOCUMENT_BASE_URL":"https://d20vmeuwl3jwbk.cloudfront.net/",
    "MUI_LICENSE_KEY" : "287507078eb754f7145be1ba4e7eb9d1Tz04MzI5MCxFPTE3MzgzMTcwOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",

  },
  "PROD": {
    "MAIN_LAMBDA_URL": "https://jpirxj2umf.execute-api.ap-south-1.amazonaws.com",
    "ZIKHARA_API_URL": "https://api.thezikhara.com/api/v1",
    "SCRIPT_URL": "https://openai.thezikhara.com/",
    "AUTOMATED_VO_URL": "https://4qmyt8eh78.execute-api.ap-south-1.amazonaws.com/dev",
    "PIANOSA_APP_V2_MAPPING": "https://app-api.thezikhara.com/api/v1",
    "DOCUMENT_BASE_URL":"https://assets.thepianosa.com/",
    "MUI_LICENSE_KEY" : "287507078eb754f7145be1ba4e7eb9d1Tz04MzI5MCxFPTE3MzgzMTcwOTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",



  }
}

export const axios = baseAxios.create({

  baseURL: configUrls[ENV].MAIN_LAMBDA_URL,
  // "http://192.168.0.62:9000/api/v1",

  headers: {
    'Content-Type': 'application/json'
  }
});
axios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
axios.interceptors.response.use((response) => {
  // console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});




export const hraxios = baseAxios.create({
  baseURL: configUrls[ENV].ZIKHARA_API_URL,
  // baseURL: "http://192.168.0.62:9000/api/v1",
  //   baseURL: "https://api.thezikhara.com/api/v1", // PROD
  // baseURL: "https://staging-apis.thezikhara.com/api/v1", //STAGING

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});
export const scriptaxios = baseAxios.create({
  baseURL: configUrls[ENV].SCRIPT_URL,
  // baseURL: "https://openai.thezikhara.com/",

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});


export const automatedVoaxios = baseAxios.create({
  baseURL: configUrls[ENV].AUTOMATED_VO_URL,
  // baseURL: "https://4qmyt8eh78.execute-api.ap-south-1.amazonaws.com/dev/", //prod

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});


export const pianosaaxios = baseAxios.create({
  baseURL: configUrls[ENV].PIANOSA_APP_V2_MAPPING,
  // baseURL: "http://192.168.0.62:4000/api/v1", //pianosa base url
 
  // baseURL: "https://app-api.thezikhara.com/api/v1", //Stagging

  headers: {
    'Content-Type': 'application/json'
  }
});
hraxios.interceptors.request.use(async function (config) {
  if (localStorage.getItem("x_acess_token")) {
    config.headers.x_acess_token = localStorage.getItem("x_acess_token")
  }
  return config;
});
hraxios.interceptors.response.use((response) => {
  console.log("error response", response.status);
  if (response.config.parse) {
    //perform the manipulation here and change the response object
  }
  return response;
}, (error) => {
  if (error?.response?.status == 401) {
    localStorage.removeItem("x_acess_token")
    localStorage.removeItem("user")
    localStorage.removeItem("roleCode")
  }
  return Promise.reject(error);
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
// export default hraxios;

// document base url
export const docBaseUrl = configUrls[ENV].DOCUMENT_BASE_URL;

// export const docBaseUrl = "https://d20vmeuwl3jwbk.cloudfront.net/"; // local and staging
// export const docBaseUrl = "https://assets.thepianosa.com/"; // prod

// mui license key

export const muiLicenseKey = configUrls[ENV].MUI_LICENSE_KEY;