import { useJumboDialog } from '@jumbo/components/JumboDialog/hooks/useJumboDialog';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';
import ArticleIcon from "@mui/icons-material/Article";
import EditIcon from '@mui/icons-material/Edit';
import { Button, Pagination } from '@mui/material';
// import { DataGrid} from '@mui/x-data-grid';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import subTaskService from 'app/services/subtask_service';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ExpenseDocumentBox from '../HrOfficeExpanses/ExpenseDocumentBox';
import InvoiceApproveForm from './InvoicesApproveForm.js';
import InvoicePaymentStatusForm from './InvoicePaymentStatusForm';
import { expenseApprovalData, hrOfficeApproveExpenseCleanUp } from 'app/redux/actions/subTaskAction';
import { use } from 'i18next';
import Swal from 'sweetalert2';

export default function Invoices() {

  const dispatch = useDispatch();
  const { setAuthToken } = useJumboAuth();
  const roleCode = localStorage.getItem("roleCode");
  const user_id = JSON.parse(localStorage.getItem("user")).id;
  ////console.log('userid', user_id)
  // const { employeeOfficeExpensesData } = useSelector(state => state.typereducer);
  const [page, setPage] = React.useState(1);
  const [offset, setOffset] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [queryOptions, setQueryOptions] = React.useState({});
  const { hideDialog, showDialog } = useJumboDialog();
  const [getInvoiceData, setGetInvoiceData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const limit = 10;
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  const [pageId, setPageId] = React.useState(1);
  var _id = pageId;

  ////console.log('use effect')
  const fetchData = async () => {
    try {
      ////console.log('log before fetch')
      const data = await subTaskService.getInvoiceById(user_id);
      ////console.log('data invoice1', data?.data);
      // const getInvoiceData = data?.data;
      setGetInvoiceData(data?.data);
      setTotalCount(data?.data?.count);
      // dispatch(expenseApprovalData(data?.data?.data));
    } catch (err) {
      if (err?.response?.status === 401) {
        setAuthToken(null);
      }
    }
  };
  // fetchData();
  useEffect(() => {
    fetchData();
  },[])





  ////console.log('invoice mapping', getInvoiceData)
  const rows = getInvoiceData.map((item) => {
    return (
      {
        id: _id++,
        invoiceId: item?.id,
        email: item?.email,
        name: item?.name,
        address: item?.address,
        invoiceNumber: item?.invoice_number,
        invoiceNotes: item?.invoice_notes,
        influencerId: item?.influencer_id.name, //item?.vendor_id.vendor_name
        influencerProgramId: item?.influencer_program_id.name,
        channelId: item?.channel_id.name,
        status: item?.status || 'Requested',
        financeStatus:item?.finance_status || '--',
        paymentStatus: item?.payment_status=='true' ? 'Paid' : 'Unpaid',
        paymentDate: item?.payment_date ? moment(item?.payment_date).format('DD MMM YYYY') : '',
        // id: _id++,
        // invoiceId: item?.id,
        // email: 'test@gmail.com',
        // name: 'Test Test',
        // address: 'Test',
        // invoiceNumber: '1452',
        // invoiceNotes: 'ok',
        // influencerId: '64faf3a1f27182c99430c2c6' ,
        // influencerProgramId: '64ddb596f27182c99430bfb3',
        // channelId: '64ddb555f27182c99430bf8d',
        // status: item?. status || 'Requested'
      }
    )
  })
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: false,
      hideable: false,
      filterable: false,
      flex: 100,
      maxWidth: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        ////console.log("params----", params.row);
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span>{params.value}</span>
            {/* <NavLink
            // to={{ pathname: `/hr-employee-leave/details/${params.row.employeeName}`, }}
            >
              <EditIcon onClick={() => showApproveExpenseDialog(params.row.invoiceId)} style={{ width: '20px', height: "20px", marginLeft: "5px" }} color="action" />
            </NavLink> */}
            <NavLink>
                            <EditIcon
                                onClick={() => {
                                  ////console.log('should not1',params.row.hrFinanceStatus)

                                  if (params.row.hrFinanceStatus === 'Requested') {
                                      ////console.log('should not')
                                      if (roleCode === 'HR' || roleCode === 'FINANCEMANAGER') {
                                          showApproveExpenseDialog(params.row.invoiceId, params.row.financeStatus);
                                      }
                                  
                                  } 
                                  else if(params.row.hrFinanceStatus === 'Approved'|| params.row.status === 'Cancelled'){
                                      return;
                                  }
                                  else if (roleCode === 'HR'|| roleCode === 'FINANCEMANAGER') {
                                      return; // Don't open dialog for 'HR' if status is not 'Requested'
                                  }
                                  else {
                                      showApproveExpenseDialog(params.row.invoiceId, params.row.financeStatus);
                                  }
                              }}
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    marginLeft: '5px',
                                    cursor: ((params.row.hrFinanceStatus != 'Requested') && roleCode === 'HR' || roleCode === 'FINANCEMANAGER') ? 'pointer' : 'not-allowed'
                                }}
                                color="action"
                                disabled={!((params.row.hrFinanceStatus != 'Requested') && roleCode === 'HR' || roleCode === 'FINANCEMANAGER')}
                            />
                        </NavLink>
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>ID</span>
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Email</span>
        </div>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Name</span>
        </div>
      ),
    },
    {
      field: 'status',
      headerName: 'Manager Status',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center', renderCell: (params) => {
        let chipColor = 'green'; // Default color
        switch (params.value) {
          case 'Requested':
            chipColor = 'blue';
            break;
          case 'Approved':
            chipColor = 'green';
            break;
          case 'Rejected':
            chipColor = 'red';
            break;
          default:
            chipColor = 'gray'; // Handle other statuses here
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 10, // Adjust the width of the chip as needed
                height: 10, // Adjust the height of the chip as needed
                backgroundColor: chipColor, // Set chip color based on status
                marginRight: 8, // Adjust the spacing between the chip and text
                borderRadius: '100%', // To make it a circle
              }}
            ></div>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Manager Status</span>
        </div>
      ),
    },
    {
      field: 'financeStatus',
      headerName: 'Finance Status',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center', renderCell: (params) => {
        let chipColor = 'green'; // Default color
        switch (params.value) {
          case 'Requested':
            chipColor = 'blue';
            break;
          case 'Approved':
            chipColor = 'green';
            break;
          case 'Rejected':
            chipColor = 'red';
            break;
          default:
            chipColor = 'gray'; // Handle other statuses here
        }

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: 10, // Adjust the width of the chip as needed
                height: 10, // Adjust the height of the chip as needed
                backgroundColor: chipColor, // Set chip color based on status
                marginRight: 8, // Adjust the spacing between the chip and text
                borderRadius: '100%', // To make it a circle
              }}
            ></div>
            {params.value}
          </div>
        );
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Finance Status</span>
        </div>
      ),
    },
    {
      field: 'address',
      headerName: 'Address',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Address </span>
        </div>
      ),
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice Number',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Invoice No. </span>
        </div>
      ),
    },
    {
      field: 'invoiceNotes',
      headerName: 'Invoice Notes',
      sortable: false,
      filterable: false,
      flex: 220,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Invoice Notes</span>
        </div>
      ),

    },
    {
      field: 'influencerId',
      headerName: 'Influencer',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Influencer</span>
        </div>
      ),
    },
    {
      field: 'influencerProgramId',
      headerName: 'Influencer Program',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Influencer Program</span>
        </div>
      ),

    },
    {
      field: 'channelId',
      headerName: 'Channel',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Channel</span>
        </div>
      ),

    },
    {
      field: 'paymentStatus',
      headerName: 'Payment Status',
      filterable: false,
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
<Button
        size="small"
        onClick={
          params.row.financeStatus === 'Approved' && params.row.paymentStatus !== 'Paid'
            ? () => showAddLeaveDialogPayment(
                params.row.invoiceId,
                params.row.paymentStatus,
                params.row.paymentDate,
                params.row.financeStatus
              )
            : undefined // Or any other function to handle non-approved status
        
          }
        disabled={params.row.status !== 'Approved'|| params.row.paymentStatus === 'Paid'}
      >
        {params.value}
      </Button>
             </div>
      ),
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Status</span>
        </div>
      ),
    },
    {
      field: 'paymentDate',
      headerName: 'Payment Date',
      // filterable: false,
      type: 'date',
      sortable: false,
      flex: 300,
      align: 'center',
      headerAlign: 'center',
     
      valueGetter: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) { // Check if date is invalid
          return ''; // Return current date if invalid
        }
        return date; // Return the parsed date if valid
      },
      renderHeader: (params) => (
        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', textAlign: 'center', lineHeight: '1', marginTop: '2px' }}>
          <span>Payment Date</span>
        </div>
      ),
    },  
  ];
 
  const showExpenseDialog = (expenseDoc) => {
    showDialog({
      title: "Expense Documents",
      content: <ExpenseDocumentBox expenseDoc={expenseDoc} />
    });
  }
  const handleExpenseUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showApproveExpenseDialog = React.useCallback((invoiceIds, financeStatus) => {
    showDialog({
      title: "Invoice",
      content: <InvoiceApproveForm invoiceIds={invoiceIds} financeStatus={financeStatus} onSave={handleExpenseUpdate} />
    });
  }, [handleExpenseUpdate, showDialog]);
  const handleValue = (event, value) => {
    setPage(value)
    setOffset((value - 1) * limit);
    setPageId(((value - 1) * limit) + 1);
  }
  const handlePaymentUpdate = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialogPayment = React.useCallback((invoiceId, paymentStatus, paymentDate, financeStatus) => {
    showDialog({
      title: "Add Payment Status",
      content: <InvoicePaymentStatusForm invoiceId={invoiceId} paymentStatus={paymentStatus} paymentDate={paymentDate} status={financeStatus} onSave={handlePaymentUpdate} />
    });
  }, [handlePaymentUpdate, showDialog]);
  const handleExport = () => {
    var filterQuery;
    // if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {
    //   filterQuery = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`
    // }
    // else {
    //   filterQuery = ''
    // }
    if (queryOptions?.filterModel?.items) {
      filterQuery = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }
  else {
      filterQuery = ''
    }


    subTaskService.getInvoiceById(user_id).then((data) => {

      const mockData = data?.data.map((item, index) => {
        return ({
          "id": index + 1,
          "email":item?.email || "--",
          "name": item?.name || "--",
          "status": item?.status || 'Requested',
          "address": item?.address || "--",
          "invoiceNumber": item?.invoice_number || "--",
          "invoiceNotes": item?.invoice_notes || "--",
          "influencerId": item?.influencer_id.name || "--",
          "influencerProgramId": item?.influencer_program_id.name || "--",
          "channelId": item?.channel_id.name || "--",
          
    
        })
      })

      // function convertToCSV(objArray) {
      //   var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      //   var str = '';
      //   ////console.log('csv array', array)
      //   for (var i = 0; i < array.length; i++) {
      //     var line = '';
      //     for (var index in array[i]) {
      //       if (line != '') line += ','

      //       line += array[i][index];
      //     }

      //     str += line + '\r\n';
      //   }

      //   return str;
      // }
      function convertToCSV(objArray) {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var csv = '';
    
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !== '') line += ',';
                var fieldValue = array[i][index];
                
                // Check if fieldValue is an array
                if (Array.isArray(fieldValue)) {
                    // Join array elements with newline characters
                    fieldValue = fieldValue.join('\n');
                } else if (typeof fieldValue === 'string' && fieldValue.charAt(0) === '0') {
                    // Check if fieldValue starts with '0'
                    // Wrap fieldValue in double quotes to preserve leading zeros
                    fieldValue = '"' + fieldValue + '"';
                }
                
                // Check if fieldValue is a string and contains special characters
                if (typeof fieldValue === 'string' && (fieldValue.includes(',') || fieldValue.includes('\n'))) {
                    // Wrap fieldValue in double quotes and escape existing double quotes
                    fieldValue = '"' + fieldValue.replace(/"/g, '""') + '"';
                }
    
                line += fieldValue;
            }
            csv += line + '\r\n';
        }
    
        return csv;
    }
      function exportCSVFile(headers, items, fileTitle) {
        if (headers) {
          items.unshift(headers);
        }

        // Convert Object to JSON
        var jsonObject = JSON.stringify(items);

        var csv = convertToCSV(jsonObject);

        var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }

      var headers = {

        id: "ID",
        email: "Email",
        name: "Name",
        status: "Status",
        address: "Address",
        invoiceNumber: "Invoice Number",
        invoiceNotes: "Invoice Notes",
        influencerId: "Influencer Id",
        influencerProgramId: "Influencer Program Id",
        channelId: "Channel Id"
      };

      var fileTitle = 'Invoices Export'; // or 'my-unique-title'



      exportCSVFile(headers, mockData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download


    }).catch((err) => {
      if (err?.response?.status == 401) {
        setAuthToken(null);
      }
    })
  }

  const onFilterChange = React.useCallback((filterModel) => {

    //  updateFilterSearchDetails(filterModel);

    setQueryOptions({ filterModel: { ...filterModel } });

    if (filterModel?.items?.[0]?.value != undefined) {
      setOffset(0);
      setPage(1);
      setPageId(1);
    }

  }, []);


  var filterString;


  React.useEffect(() => {
    // if (queryOptions?.filterModel?.items?.[0]?.columnField != undefined) {

    //   filterString = `&${queryOptions?.filterModel?.items?.[0]?.columnField}=${(queryOptions?.filterModel?.items?.[0]?.value) ? queryOptions?.filterModel?.items?.[0]?.value : ''}`

    // } else {
    //   filterString = ''
    // }

    if (queryOptions?.filterModel?.items) {
      filterString = queryOptions.filterModel.items
          .filter(item => item.field !== undefined) // Filter out items without a field
          .map(item => `&${item.field}=${item.value || ''}`) // Generate filter strings for each item
          .join(''); // Join all filter strings into a single string
  }else {
      filterString = ''
    }

    // subTaskService.getInvoiceById(user_id).then((data) => {
    //   ////console.log('data invoice',data?.data)
    //   const getInvoiceData = data?.data
    //   setGetInvoiceData(data?.data);
    //   setTotalCount(data?.data?.count);
    //   // dispatch(expenseApprovalData(data?.data?.data))

    // }).catch((err) => {
    //   if (err?.response?.status == 401) {
    //     setAuthToken(null);
    //   }
    // })
    return () => {
      dispatch(hrOfficeApproveExpenseCleanUp())
    }
    // subTaskService.subTaskGetMyFootageUploadData(filterString).then((data)=>{
    //   dispatch(uploadBatchExport(data?.data))

    // }).catch((err) => {
    //   if (err?.response?.status == 401) {
    //     setAuthToken(null);
    //   }
    // })

  }, [offset, queryOptions])
  const handleLeaveAdd = React.useCallback(() => {
    hideDialog();
    // setContactsListRefresh(true);
  }, [hideDialog]);
  const showAddLeaveDialog = React.useCallback(() => {
    showDialog({
      title: "Update Invoice",
      //   content: <OfficeExpenseForm onSave={handleLeaveAdd} />
    });
  }, [handleLeaveAdd, showDialog]);

  const handleSelectionChange = (selection) => {
    setSelectedRows(selection.selectionModel);
  };

  const handleBulkApproval = () =>{
    ////console.log('')
    // const invoices =  rowSelectionModel.map(itm=> {return rows[itm-1]} );
    const invoiceIds = rowSelectionModel?.map(itm=>itm.invoiceId);
    // rows?.filter((itm,idx)=>rowSelectionModel?.includes()).map(itm=>)
    showApproveExpenseDialog(invoiceIds)
  }
  const handleFinanceBulkApproval = () =>{
    ////console.log('')
    // const invoices =  rowSelectionModel.map(itm=> {return rows[itm-1]} );
    const invoiceIds = rowSelectionModel?.map(itm=>itm.invoiceId);
    // rows?.filter((itm,idx)=>rowSelectionModel?.includes()).map(itm=>)
    showApproveExpenseDialog(invoiceIds,'Requested')
  }

////console.log('rowSelectionModel',rowSelectionModel);

const handleCellClick = (params, event) => {
  const columnId = params.field; // Get the value of the clicked cell
  ////console.log('params.field',params.field)
  if (columnId != 'id' && columnId != '__check__' && columnId != 'paymentStatus') {
    const cellValue = params.value; // Get the value of the clicked cell

    Swal.fire({
        // title: 'Cell Content',
        html: `<div style="white-space: pre-wrap;">${cellValue}</div>`,
        // showCloseButton: true,
        showConfirmButton: false,
        customClass: {
            popup: 'my-custom-size', // Apply custom class to the dialog box
        },
        didOpen: () => {
            const swalPopup = Swal.getPopup();
            if (swalPopup) {
                swalPopup.style.width = '400px'; // Modify the width as needed
            }
        },
    });
}
};

  return (
    <>

      <div style={{ height: '60px', width: '100%' }}>
      {roleCode !== 'FINANCEMANAGER' && rowSelectionModel.length > 0 && (
        <Button variant="contained" onClick={handleBulkApproval}>
          Approve/Reject
        </Button>
      )}
      {roleCode === 'FINANCEMANAGER' && rowSelectionModel.length > 0 && (
        <Button variant="contained" sx={{marginLeft:'5px'}} onClick={handleFinanceBulkApproval}>
          Approve/Reject Finance
        </Button>
      )}
        <Button onClick={handleExport} sx={{ float: 'right' }} variant="contained" color='secondary'> 
          Export Invoices
        </Button>

      </div>
      <div style={{ height: '100%', width: '100%' }}>
{/* roleCode == 'SUPERADMIN' &&  */}
        <DataGridPro checkboxSelection isRowSelectable={(params) => params.row.financeStatus != "Approved" && params.row.status != "Rejected" && params.row.status != "Cancelled"} sx={{ backgroundColor: '#F6F4FA' }}
      
          rows={[...rows]}
          columns={[...columns]}
          pageSize={30}
          autoPageSize={true}
          autoHeight={true}
          density='standard'
          filterMode='server'
          
          onFilterModelChange={onFilterChange}
          // hideFooter={true}   
          onRowSelectionModelChange={(newRowSelectionModel) => {
            const selectedRowData = newRowSelectionModel.map(itm=> {return rows[itm-1]} );
            setRowSelectionModel(selectedRowData);
          }}
          // check
          // onRowSelectionModelChange={(newRowSelectionModel) => {
          //   setRowSelectionModel(newRowSelectionModel);
          // }}
          rowSelectionModel={rowSelectionModel?.map(i=>i.id)}
          onCellClick={(params, event) => handleCellClick(params, event)}

        />
        
     
        {/* <Pagination count={Math.ceil(totalCount / limit)} sx={{ mt: 3, float: 'right', color: "#F6F4FA" }} page={page} onChange={handleValue} /> */}
      </div>

    </>
  );
}