import { FOOTAGES_UPLOAD_GET_DATA, SUBTASK_CAMERA_DETAILS, SUBTASK_DETAIL_CLEAN_UP, SUBTASK_DETAIL_GET_DATA, SUBTASK_DETAIL_GET_DATA_SIDE_INFO, SUBTASK_DETAIL_GET_MY_SUBTASK, SUBTASK_GET_DATA, SUBTASK_STUDIO_DETAILS, TABLE_VIEW_DATA_CLEAN_UP, USER_LIST_DATA_CLEAN_UP, GET_HR_EMPLOYEE_LEAVE_DATA, GET_EMPLOYEE_LEAVE_APPROVAL_DATA, GET_HR_OFFICE_EXPENSES_DATA, GET_EMPLOYEE_OFFICE_EXPENSES_DATA, ASIN_NUMBER_GET_DATA, SCRIPT_MODELS_DATA, AUTOMATED_VO_DATA, EMPLOYEE_LEAVE_DATA_CLEAN_UP, EMPLOYEE_APPROVE_LEAVE_DATA_CLEAN_UP, HR_OFFICE_EXPENSE_DATA_CLEAN_UP, HR_OFFICE_APPROVE_EXPENSE_DATA_CLEAN_UP, GET_VENDOR_DATA, GET_VENDOR_NAME, GET_OPERATIONAL_EXPENSES_DATA, GET_ALL_OFFICE_EXPENSESS_DATA, GET_ALL_EXPENSESS_DATA_CLEAN_UP,GET_ALL_OP_EXPENSESS_DATA,GET_ALL_OP_EXPENSESS_DATA_CLEAN_UP ,GET_OP_EXPENSES_DATA,OP_APPROVE_EXPENSE_DATA_CLEAN_UP,GET_INFLUENCER_APP_MAPPING_DATA,INFLUENCER_APP_MAPPING_DATA_CLEAN_UP, GET_PROJECT_MANAGER_NAME, PROJECT_MANAGER_NAME_CLEAN_UP} from "app/utils/constants/types";

const INIT_STATE = {
    details: [],
    detailedInfos: [],
    detailedSideInfos: [],
    detailedGetMySubTask: [],
    footageUploadData: [],
    studioList: [],
    cameraList: [],
    vendorData:[],
    vendorNameData: [],
    projectManagerNameData: [],
    hrEmployeeLeaveData: [],
    hrOfficeExpensesData: [],
    operationalExpensesData: [],
    asinNumberDetailData: [],
    employeeLeaveApprovalData: [],
    employeeOfficeExpensesData: [],
    opExpensesData:[],
    allOfficeExpensesData: [],
    scriptModels: [],
    automatedVO:[],
    allOpExpensesData:[],
    influencerAppMappingData: []
};
const reducerFunc = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SUBTASK_GET_DATA: {
            return {
                ...state,
                details: action.payload,
            }
        }

        case SUBTASK_DETAIL_GET_DATA: {
            return {
                ...state,
                detailedInfos: action.payload
            }
        }

        case SUBTASK_DETAIL_GET_DATA_SIDE_INFO: {
            return {
                ...state,
                detailedSideInfos: action.payload

            }
        }

        case SUBTASK_DETAIL_GET_MY_SUBTASK: {
            return {
                ...state,
                detailedGetMySubTask: action.payload
            }
        }

        case SUBTASK_DETAIL_CLEAN_UP: {
            return {
                ...state,
                detailedInfos: [],
                detailedSideInfos: [],
                detailedGetMySubTask: []

            }
        }

        case TABLE_VIEW_DATA_CLEAN_UP: {
            return {
                ...state,
                footageUploadData: []
            }
        }

        case FOOTAGES_UPLOAD_GET_DATA: {
            return {
                ...state,
                footageUploadData: action.payload
            }
        }

        case USER_LIST_DATA_CLEAN_UP: {
            return {
                ...state,
                details: []
            }
        }

        case SUBTASK_STUDIO_DETAILS: {
            return {
                ...state,
                studioList: action.payload
            }
        }

        case SUBTASK_CAMERA_DETAILS: {
            return {
                ...state,
                cameraList: action.payload
            }
        }

        case GET_HR_EMPLOYEE_LEAVE_DATA: {
            return {
                ...state,
                hrEmployeeLeaveData: action.payload
            }
        }
        case GET_VENDOR_DATA: {
            //console.log('get vendor data',action.payload)
            return {
                ...state,
                vendorData: action.payload

            }
        }
        case GET_OPERATIONAL_EXPENSES_DATA: {
            //console.log('get operational expenses data',action.payload)
            return {
                ...state,
                operationalExpensesData: action.payload

            }
        }
        case GET_VENDOR_NAME: {
            //console.log('get vendor name',action.payload)
            return {
                ...state,
                vendorNameData: action.payload

            }
        }
        case GET_PROJECT_MANAGER_NAME: {
            //console.log('get program manager name',action)
            return {
                ...state,
                projectManagerNameData: action.payload

            }
        }
        case PROJECT_MANAGER_NAME_CLEAN_UP: {
            return {
                ...state,
                projectManagerNameData: [],
            }
        }
        case GET_EMPLOYEE_LEAVE_APPROVAL_DATA: {
            return {
                ...state,
                employeeLeaveApprovalData: action.payload
            }
        }
        case GET_HR_OFFICE_EXPENSES_DATA: {
            return {
                ...state,
                hrOfficeExpensesData: action.payload
            }
        }
        case GET_EMPLOYEE_OFFICE_EXPENSES_DATA: {
            return {
                ...state,
                employeeOfficeExpensesData: action.payload
            }
        }
        case GET_OP_EXPENSES_DATA: {
            return {
                ...state,
                opExpensesData: action.payload
            }
        }
        case GET_ALL_OFFICE_EXPENSESS_DATA: {
            return {
                ...state,
                allOfficeExpensesData: action.payload
            }
        }
        case GET_ALL_OP_EXPENSESS_DATA: {
            return {
                ...state,
                allOpExpensesData: action.payload
            }
        }
        case ASIN_NUMBER_GET_DATA: {
            return {
                ...state,
                asinNumberDetailData: action.payload,
            }
        }
        case SCRIPT_MODELS_DATA: {
            return {
                ...state,
                scriptModels: action.payload,
            }
        }
        case AUTOMATED_VO_DATA: {
            return {
                ...state,
                automatedVO: action.payload,
            }
        }
        case EMPLOYEE_LEAVE_DATA_CLEAN_UP: {
            return {
                ...state,
                hrEmployeeLeaveData: [],
            }
        }
        case EMPLOYEE_APPROVE_LEAVE_DATA_CLEAN_UP: {
            return {
                ...state,
                employeeLeaveApprovalData: [],
            }
        }
        case HR_OFFICE_EXPENSE_DATA_CLEAN_UP: {
            return {
                ...state,
                hrOfficeExpensesData: [],
            }
        }
        case HR_OFFICE_APPROVE_EXPENSE_DATA_CLEAN_UP: {
            return {
                ...state,
                employeeOfficeExpensesData: [],
            }
        }
        case OP_APPROVE_EXPENSE_DATA_CLEAN_UP: {
            return {
                ...state,
                opExpensesData: [],
            }
        }
        case GET_ALL_EXPENSESS_DATA_CLEAN_UP: {
            return {
                ...state,
                allOfficeExpensesData: [],
            }
        }
        case GET_ALL_OP_EXPENSESS_DATA_CLEAN_UP: {
            return {
                ...state,
                allOpExpensesData: [],
            }
        }
        case GET_INFLUENCER_APP_MAPPING_DATA: {
            return {
                ...state,
                influencerAppMappingData: action.payload

            }
        }
        case INFLUENCER_APP_MAPPING_DATA_CLEAN_UP: {
            return {
                ...state,
                influencerAppMappingData: [],
            }

        }
        default: {
            return state;
        }
    }
};

export default reducerFunc;
