import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { Avatar, Card, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";

const Item = styled(Span)(({ theme }) => ({
    padding: theme.spacing(0, 1),
}));

const AsinItem = ({ asinItem, view }) => {
    // var url = "/footages/detailedView?subTaskId=" + asinItem?._id
    var amazonUrl = "https://www.amazon.in/dp/" + asinItem?.asin
    return (

        <Card sx={{ mb: 1 }}>
            <Stack direction={"row"} alignItems={"center"} sx={{ p: theme => theme.spacing(2, 1) }}>
                <Item
                    sx={{
                        flex: { xs: 1, md: '0 1 30%', lg: '0 1 35%' }
                    }}
                >
                    <Stack direction={'row'} alignItems={'center'}>
                        <Item>
                            <Badge overlap="circular" variant="dot"
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}>
                                <Avatar
                                    sx={{
                                        width: 56,
                                        height: 56
                                    }}
                                    alt={'Path Not Pound'}
                                    src={`https://ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=IN&ASIN=${asinItem?.asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL80`}
                                />
                            </Badge>
                        </Item>
                        <Item>
                            <a href={amazonUrl} target="_blank"><Typography variant={"h6"} mb={.5}>{asinItem?.asin}</Typography></a>
                            <Typography variant={"body1"} color="text.secondary" fontSize={13} textOverflow={'clip'}>{asinItem?.title}</Typography>
                        </Item>
                    </Stack>
                </Item>
                <Item
                    sx={{
                        // flexBasis: '30%',
                        ml: 'auto',
                        display: { xs: 'none', lg: 'block' }
                    }}
                >
                    <Stack spacing={2} direction={"row"} alignItems={"center"} sx={{ textAlign: 'center' }}>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem?.productName}</Typography>
                            <Typography variant={"body1"} color="text.secondary">product Name</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem?.listPrice?.currency}{asinItem?.listPrice?.value}</Typography>
                            <Typography variant={"body1"} color="text.secondary">MRP</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem?.price}{asinItem?.price?.value}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Price</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem.breadcrumbs}</Typography>
                            <Typography variant={"body1"} color="text.secondary"></Typography>
                        </Item>

                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem?.star}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Rating</Typography>
                        </Item>
                        <Item>
                            <Typography variant={"h6"} mb={.5}>{asinItem?.delivery}</Typography>
                            <Typography variant={"body1"} color="text.secondary">Delivery</Typography>
                        </Item>
                    </Stack>
                </Item>
            </Stack>
        </Card>

    );
};

export default AsinItem;
